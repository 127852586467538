@import "../../node_modules/slick-carousel/slick/slick.css";
@import "../../node_modules/slick-carousel/slick/slick-theme.css";
.result-landing-container {
  position: relative;
  // height: 100vh;

  .header-container {
    position: relative;
    margin-bottom: 20px;
    z-index: 11;
    .inner-wrapper {
      // box-shadow: none;
      position: fixed;
      z-index: 1;
      background-color: white;
      width: 100%;
      .header-links-wrapper {
        display: none;
      }
      @media only screen and (min-width: 1200px) {
        .header-links-wrapper {
          display: flex;
          width: 400px;
          justify-content: space-between;
          .link {
            position: relative;
            cursor: pointer;
            &.active {
              .vertical-line {
                width: 100%;
                height: 5px;
                background-color: #006f44;
                position: absolute;
                bottom: -25px;
              }
            }
          }
        }
        .header-icons {
          margin-left: 20%;
        }
      }
    }
    .result-bg-container {
      position: relative;

      padding-top: 70px;
      .result-bg-img {
        width: 100%;
        height: 280px;
        // background-image: url("../../assets/results-bg-leaf.png");
        background-size: cover;
        background-position: center;
      }
      .result-title-wrapper {
        width: 500px;
        min-height: 180px;
        position: relative;
        margin: auto;
        margin-top: -80px;
        background: #ffffff;
        border: 1px solid #cac0ac;
        box-sizing: border-box;
        box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 8%);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 25px;
        margin-bottom: 20px;
        @include media_query(M) {
          width: 343px;
        }
        .user-name {
          font-weight: 600;
          font-size: 32px;
          line-height: 36px;
          text-transform: capitalize;
        }
        .details {
          font-size: 16px;
          line-height: 24px;
          text-align: center;
        }
      }
      .results-options-wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 0 80px;
        border-bottom: 1px solid #d8d8d8;
        @include media_query(M) {
          padding: 0 10px;
          justify-content: unset;
          overflow-y: auto;
          white-space: nowrap;
        }
        // For tablet Portrait
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          justify-content: center;
        }
        .options {
          display: flex;
          align-items: center;
          justify-content: center;
          // border-bottom: 3px solid #006F44;
          padding: 15px 0;
          margin-right: 160px;
          cursor: pointer;
          @include media_query(M) {
            margin-right: 40px;
          }
          &:last-child {
            margin-right: 0px;
          }
          img {
            width: 22px;
            height: 22px;
            // background-image: url("../images/moon.svg");
            // &.active {
            //   filter: invert(15%) sepia(94%) saturate(7263%) hue-rotate(165deg)
            //     brightness(91%) contrast(101%);
            // }
          }
          .option {
            margin-left: 12px;
          }
          &.active {
            border-bottom: 3px solid #006f44;
            img {
              filter: invert(15%) sepia(94%) saturate(7263%) hue-rotate(165deg)
                brightness(91%) contrast(101%);
            }
            .option {
              color: #006f44;
            }
          }
        }
      }
    }
  }
  .middle-area {
    padding: 50px 0;
    @include media_query(M) {
      padding: 20px 0;
    }
    .inner-wrapper {
      .details-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 50%;
        margin: auto;
        margin-bottom: 50px;
        @include media_query(M) {
          width: 100%;
          padding: 0 40px;
        }
        .for-your {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 15px;
        }
        .details-title {
          font-weight: 600;
          font-size: 42px;
          line-height: 48px;
          margin-bottom: 25px;
        }
        .details-description {
          font-size: 16px;
          line-height: 24px;
        }
      }
      .food-container,
      .tips-container {
        margin-bottom: 60px;
        .inner-wrapper {
          text-align: center;
          .food-card-wrapper {
            text-align: left;
            // .row {
            // margin: 0;
            .inner-wrapper {
              width: 100%;
              margin-bottom: 30px;
              @include media_query(M) {
                margin-bottom: 40px;
                padding-left: 0;
                padding-right: 0;
              }
              .cards-area {
                display: flex;
                justify-content: center;
                margin: 0;
              }
              .slick-slider {
                .cards-wrapper {
                  @include media_query(M) {
                    padding-left: 0;
                  }
                }
                .slick-list {
                  @include media_query(M) {
                    padding: 0 16px !important;
                    .slick-track {
                      display: flex;
                      margin-bottom: 20px;
                      .slick-slide {
                        height: auto;
                        & > div {
                          height: 100%;
                          .cards-wrapper {
                            height: 100%;
                            .white-cards {
                              margin-bottom: 0px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .slick-dots {
                  li {
                    button {
                      &::before {
                        content: "";
                        background: #e4e4e4;
                        width: 7px;
                        height: 7px;
                        border-radius: 50%;
                        opacity: 1;
                      }
                    }
                  }
                  .slick-active {
                    button {
                      &::before {
                        background: #66a98f;
                      }
                    }
                  }
                }
              }
            }
            // }
          }
        }
      }
      //tips container
      .tips-container {
        .inner-wrapper {
          .image-wrapper {
            background-image: url("../../assets/tips-bg.png");
          }
        }
      }
      //product container
      .product-container {
        margin-bottom: 60px;
        text-align: center;
        .inner-wrapper {
          .image-wrapper {
            background-image: url("../../assets/product.bg.png");
          }
          .detail-wrapper {
            margin-bottom: 30px;
            text-align: left;
            .description {
              font-size: 15px;
              line-height: 24px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              text-align: center;
              width: 35%;
              margin: auto;
              margin-bottom: 30px;
              @include media_query(M) {
                width: auto;
              }
            }
            .product-wrapper {
              padding: 0px 40px;
              margin-bottom: 40px;
              @include media_query(M) {
                padding: 0px 16px;
                margin-bottom: 16px;
              }
              .inner-wrapper {
                background: #ffffff;
                border: 1px solid #cac0ac;
                box-sizing: border-box;
                border-radius: 10px;
                height: auto;
                width: 100%;
                padding: 40px;
                @include media_query(M) {
                  height: auto;
                  padding: 18px 20px;
                  position: relative;
                }
                // For tablet Portrait
                @media only screen and (min-width: 768px) and (orientation: portrait) {
                  height: auto;
                }
                .left-wrapper {
                  height: 100%;
                  @include media_query(M) {
                    margin-bottom: 40px;
                  }
                  .slider-wrapper {
                    // display: flex;
                    width: 100%;
                    height: 100%;
                    .silder-images-wrapper {
                      width: 100%;
                      height: 100%;
                      // display: flex;
                      // overflow-y: auto;
                      .slick-slider {
                        height: 100%;
                        @include media_query(M) {
                          .slick-prev,
                          .slick-next {
                            height: 21px;
                            width: 12px;
                            // background-image: url("../images/slider-next.svg");
                            background-repeat: no-repeat;
                            background-size: contain;
                            &::before {
                              display: none;
                            }
                          }
                          .slick-prev {
                            background-image: url("../images/slider-prev.svg");
                            margin-left: 10px;
                          }
                          .slick-next {
                            background-image: url("../images/slider-next.svg");
                            margin-right: 10px;
                          }
                        }
                        .slick-list {
                          padding-left: 77px;
                          height: 100% ;
                          // max-height: 400px;
                          @include media_query(M) {
                            padding-left: 0px;
                          }
                          .slick-slide {
                            max-width: 100%;
                            // width: auto !important;
                            @include media_query(M) {
                              // width: 271px !important;
                            }
                            .slider-main-img {
                              min-width: 100%;
                              min-height: 100%;
                              display: flex !important;
                              justify-content: center;
                              padding: 20px 30px;
                              @include media_query(M) {
                                padding: 0;
                              }
                              .slider-img {
                                background-repeat: no-repeat;
                                background-size: contain;
                                max-height: 435px;
                              }
                            }
                          }
                        }
                        .slick-dots {
                          top: 0;
                          bottom: auto;
                          display: flex;
                          width: 75px;
                          @include media_query(M) {
                            top: auto;
                            bottom: -25px;
                            width: 100%;
                          }
                          li {
                            width: 100%;
                            height: 77px;
                            margin-bottom: 10px;
                            @include media_query(M) {
                              margin-bottom: 0px;
                              height: 20px;
                              width: 20px;
                              button {
                                &::before {
                                  content: "";
                                  background: #e4e4e4;
                                  width: 7px;
                                  height: 7px;
                                  border-radius: 50%;
                                  opacity: 1;
                                }
                              }
                            }
                            .thumbnail-img {
                              width: 100%;
                              height: 100%;
                              border: 1px solid #e4e4e4;
                              box-sizing: border-box;
                              border-radius: 5px;
                              margin-bottom: 10px;
                              // background-image: url("../../assets/product-img.png");
                              // &.slick-active {
                              //   border: 2px solid #66a98f;
                              // }
                              // &:nth-child(2) {
                              //   background-image: url("../../assets/p2.png");
                              // }
                              // &:nth-child(3) {
                              //   background-image: url("../../assets/p3.png");
                              // }
                              img {
                                max-height: 100%;
                              }
                            }
                          }
                          .slick-active {
                            border: 2px solid #66a98f;
                            border-radius: 5px;
                            @include media_query(M) {
                              border: none;
                              button {
                                &::before {
                                  background: #66a98f;
                                }
                              }
                            }
                          }
                        }
                      }
                      .fallback-main-img {
                        width: 100%;
                        height: 100%;
                        // display: flex !important;
                        // justify-content: center;
                        // padding: 20px 30px;
                        // @include media_query(M) {
                        //   padding: 0;
                        // }
                        .fallback-img {
                          background-repeat: no-repeat;
                          background-size: contain;
                          width: 100%;
                          height: 100%;
                        }
                      }
                    }
                  }
                }
                .right-wrapper {
                  height: 100%;
                  @include media_query(M) {
                    height: auto;
                    padding: 0;
                    position: unset;
                  }
                  // For tablet Portrait
                  @media only screen and (min-width: 768px) and (orientation: portrait) {
                    padding-left: 10px;
                  }
                  .product-detail-wrapper {
                    .product-detail {
                      margin-bottom: 20px;
                      @include media_query(M) {
                        width: 70%;
                        float: left;
                      }
                      // For tablet Portrait
                      @media only screen and (min-width: 768px) and (orientation: portrait) {
                        width: auto;
                        float: none;
                      }
                      .need-state-icons {
                        margin-bottom: 20px;
                        width: 100px;
                        display: flex;
                        justify-content: space-between;
                        .icon-list {
                          width: 18px;
                          height: 18px;
                          // background-image: url("../images/moon.svg");
                          //For this color #6D6D6D
                          filter: invert(45%) sepia(0%) saturate(0%)
                            hue-rotate(244deg) brightness(93%) contrast(84%);
                        }
                        @include media_query(M) {
                          position: absolute;
                          top: 10px;
                        }
                        // For tablet Portrait
                        @media only screen and (min-width: 768px) and (orientation: portrait) {
                          position: unset;
                        }
                      }
                      .product-name {
                        font-weight: bold;
                        font-size: 32px;
                        line-height: 36px;
                        margin-bottom: 15px;
                        width: 90%;
                        @include media_query(M) {
                          font-size: 21px;
                          line-height: 24px;
                          margin-bottom: 8px;
                        }
                      }
                      .product-recommended {
                        font-size: 16px;
                        line-height: 24px;
                      }
                      .rating-star-wrapper {
                        font-size: 16px;
                        line-height: 24px;
                        .label {
                          margin-left: 8px;
                        }
                      }
                    }
                    .product-price {
                      font-weight: bold;
                      font-size: 32px;
                      line-height: 36px;
                      margin-bottom: 25px;
                      @include media_query(M) {
                        font-size: 21px;
                        line-height: 26px;
                        float: right;
                        // margin-top: 35px;
                      }
                      // For tablet Portrait
                      @media only screen and (min-width: 768px) and (orientation: portrait) {
                        float: none;
                      }
                    }
                  }
                  .product-description-wrapper {
                    margin-bottom: 30px;
                    .descriptions {
                      font-size: 16px;
                      line-height: 24px;
                      margin-bottom: 8px;
                    }
                    .learn-more {
                      font-weight: bold;
                      font-size: 16px;
                      line-height: 24px;
                      color: #006f44;
                      cursor: pointer;
                      position: relative;
                      &::after {
                        content: "";
                        width: 7px;
                        height: 11px;
                        background-image: url("../images/green-arrow.svg");
                        background-repeat: no-repeat;
                        background-size: contain;
                        display: inline-block;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        margin-left: 5px;
                      }
                    }
                  }
                  .add-to-cart-wrapper {
                    display: flex;
                    .btn-area {
                      min-width: 165px;
                      width: auto;
                      height: 48px;

                      .btn-icon {
                        //background-color: #707070;
                        // text-align: right;
                        // padding: 0 35px;
                        padding-left: 50px;
                        padding-right: 10px;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 18px;
                        &::after {
                          content: "";
                          background-image: url("../images/cart.svg");
                          left: 25px;
                          right: auto;
                          filter: invert(100%) sepia(0%) saturate(0%)
                            hue-rotate(17deg) brightness(111%) contrast(101%);
                        }
                        @include media_query(M) {
                          // padding: 0 18px;
                        }
                      }
                      @include media_query(M) {
                        width: 185px;
                        white-space: nowrap;
                        min-width: auto;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
