.error-screen-container {
  min-width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  @include media_query(M) {
    height: calc(100vh - 150px);
  }
  .error-screen-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    align-items: center;
    .error-middle-area {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .title {
        height: 124px;
        background: #f4f4f4;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 20px;
        padding: 0 100px;
        text-align: center;
        @include media_query(M) {
          height: 100px;
          padding: 0 20px;
        }
      }
      .sub-title {
        width: 40%;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        @include media_query(M) {
          width: 85%;
        }
      }
      .btn-area {
        margin-top: 30px;
        width: auto;
        min-width: 135px;
        height: 36px;
        @include media_query(M) {
          width: 90%;
        }
        // For tablet Portrait
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          width: auto;
        }
        .app-btn {
          text-align: center;
          box-shadow: none;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}
