.connection-moment-container {
  min-width: 100%;
  display: flex;
  align-items: center;
  @include media_query(M) {
    height: calc(100vh - 150px);
  }
  .cm-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    max-height: 100%;
    align-items: center;
    padding: 20px 0;
    .dyk-banner-wrapper {
      width: 100%;
      height: 250px;
      position: relative;
      .dyk-banner {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .overlay {
        position: absolute;
        z-index: 0;
      }
      .dyk-label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 42px;
        line-height: 36px;
        color: #ffffff;
        // z-index: 1;
        @include media_query(M) {
          font-size: 32px;
        }
      }
    }
    .circular-progress-wrapper {
      width: 200px;
      height: 200px;
      margin-bottom: 40px;
      .circle-img {
        width: 92%;
        /* margin-top: -5px; */
        height: 92%;
        border-radius: 50%;
      }
    }
    .middle-area {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .title {
        min-height: 124px;
        background: #f4f4f4;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 20px;
        padding: 20px;
        text-align: center;
        @include media_query(M) {
          min-height: 100px;
          font-size: 24px;
          line-height: 28px;
        }
      }
      .sub-title {
        width: 40%;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 25px;
        @include media_query(M) {
          width: 85%;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
  &.top-align {
    align-items: unset;
    .cm-wrapper {
      padding: 0;
    }
  }
  .bottom-section {
    box-shadow: 0px -4px 5px rgb(0 0 0 / 8%);
    .progress-bar-wrapper {
      display: none;
    }
    .inner-wrapper {
      height: 100%;
      flex-direction: row;
      .btn-wrapper {
        justify-content: flex-end;
        .back-button {
          display: none;
        }
        .btn-area {
          width: 160px;
          height: 48px;
          .app-btn{
            font-size: 14px;
          }
        }
      }
    }
  }
}
