.personal-info-container {
  height: calc(100vh - 150px);
  width: 100%;
  position: absolute;
  top: 70px;
  overflow-y: auto;
  .inner-wrapper {
    // padding: 25px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .background-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      min-width: 100%;
      background-color: #f4f4f4;
      padding: 20px 20%;
      margin-left: -11px;
      margin-right: -11px;
      margin-bottom: 15px;
      position: relative;
      height: 340px;
      .account-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 18px;
        min-width: 340px;
        height: 55px;
        background: $white;
        border: 1px solid #d6d6d6;
        box-sizing: border-box;
        box-shadow: 0px 0px 5px rgb(0 0 0 / 8%);
        border-radius: 12px;
        position: absolute;
        top: 20px;
        // margin-bottom: 50px;
        .account-msg {
          // font-family: "Montserrat";
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: #414141;
          margin-right: 15px;
        }
        .account-signIn {
          // font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 15px;
          text-transform: capitalize;
          color: $darkGreen;
          border-bottom: 2px solid #006f44;
          padding: 6px 0px;
          cursor: pointer;
        }
        @include media_query(M) {
          // margin-bottom: 80px;
          // width: 100%;
        }
        //For Phone landscape
        @media only screen and (orientation: landscape) {
          @include media_query(L) {
            // margin-bottom: 50px;
            width: 340px;
          }
        }
        // For tablet Portrait
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          // margin-bottom: 100px;
          width: 340px;
        }
      }
      .bg-image-wrapper {
        width: 270px;
        height: 400px;
        // margin-bottom: 50px;
        position: relative;

        .image {
          width: 100%;
          height: 100%;
          background-image: url("../../assets/leaf.png");
          background-repeat: no-repeat;
          background-size: contain;
          display: inline-block;
          @include media_query(M) {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            width: 264px;
          }
          //For Phone landscape
          @media only screen and (orientation: landscape) {
            @include media_query(L) {
              position: relative;
              width: 100%;
            }
          }
          // For tablet Portrait
          @media only screen and (min-width: 768px) and (orientation: portrait) {
            position: relative;
            width: 100%;
          }
        }

        @include media_query(M) {
          // width: 100%;
          // height: 164px;
          // margin-bottom: 100px;
        }
        //For Phone landscape
        @media only screen and (orientation: landscape) {
          @include media_query(L) {
            // width: 370px;
            // height: 260px;
            // margin-bottom: 50px;
          }
        }
        // For tablet Portrait
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          width: 270px;
          // height: 260px;
          // margin-bottom: 100px;
        }
      }
      .name-input-wrapper {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .small-label {
          // font-family: "Temeraire";
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 8px;
        }
        .question-label {
          margin-bottom: 0;
        }
        @include media_query(M) {
          text-align: left;
        }
        //For Phone landscape
        @media only screen and (orientation: landscape) {
          @include media_query(L) {
            text-align: center;
          }
        }
        // For tablet Portrait
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          text-align: center;
        }
      }
      @include media_query(M) {
        padding: 20px 16px;
      }

      //For leaf background image
      &::after,
      &::before {
        content: "";
        position: absolute;
        width: 160px;
        height: 270px;
        background-image: url("../images/background-leaf.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
      &::after {
        right: 0;
        bottom: 0;
        top: 100px;
        @include media_query(M) {
          background-image: url("../images/mb-right-leaf-bg.svg");
          width: 80px;
        }
      }
      &::before {
        left: -15px;
        bottom: 80px;
        transform: rotateZ(170deg) rotate(275deg) rotateY(180deg);
        @include media_query(M) {
          background-image: url("../images/mb-left-leaf-bg.svg");
          left: 0;
          transform: none;
        }
      }
    }

    .input-wrapper {
      .custom-input {
        font-size: 14px;
        line-height: 18px;
        text-transform: capitalize;
        &.no-caplitalize {
          text-transform: none;
        }
        &::placeholder {
          text-transform: none;
        }
      }
    }
    @include media_query(M) {
      align-items: unset;
      padding: 25px 20px;
      padding-bottom: 50px;
    }
    //For Phone landscape
    @media only screen and (orientation: landscape) {
      @include media_query(L) {
        align-items: center;
        // padding: 25px 0px;
        padding-bottom: 30px;
      }
    }
    // For tablet Portrait
    @media only screen and (min-width: 768px) and (orientation: portrait) {
      align-items: center;
      padding: 50px 0px;
      padding-bottom: 30px;
    }
  }
}
