.view-test-container {
  background: #fafafa;
  position: relative;
  padding: 35px;
  height: 100vh;
  padding-top: 100px;
  .view-test-cases-wrapper {
    background: #ffffff;
    border: 1px solid #e4e4e4;
    box-sizing: border-box;
    border-radius: 15px;
    height: 100%;
    width: 100%;
    padding: 25px 35px;
    .filter-wrapper {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      .main-input {
        position: relative;
        // width: 345px;
        display: flex;
        align-items: center;
        .title {
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          text-align: center;
          margin-right: 30px;
        }
        .search-wrapper {
          width: 345px;
          position: relative;
          .custom-input {
            font-size: 16px;
          }
          .search-icon,
          .cross-icon {
            width: 15px;
            height: 15px;
            position: absolute;
            top: 0;
            right: 10px;
            bottom: 0;
            margin: auto;
          }
        }
        .filter-icon {
          background-image: url("../images/admin-filter.svg");
          width: 20px;
          height: 20px;
          margin-left: 24px;
        }
      }
      .create-test-case-btn {
        display: flex;
        align-items: center;
        .filter-btn-area {
          margin-right: 40px;
          font-size: 14px;
          line-height: 18px;
          border-bottom: 1px solid #2c2c22;
          cursor: pointer;
        }
        .btn-area {
          width: 180px;
          height: 35px;
        }
      }
    }
    .filter-tags {
      .tags-container {
        .tags-wrapper {
          width: 100%;
          .tags-list {
            align-items: center;
            .tags {
              height: 24px;
              min-width: 80px;
              border: 1px solid #006f44;
              border-radius: 24px;
              .label {
                color: #006f44;
              }
              .cross-icon {
                background-image: url("../images/green-close.svg");
              }
            }
            .clear-filter {
              font-size: 14px;
              font-weight: 700;
              line-height: 18px;
              color: #006f44;
              cursor: pointer;
              border-bottom: 2px solid #006f44;
            }
          }
        }
      }
    }
    .table-view-test-cases {
      margin: 0 -35px;
      height: calc(100% - 50px);
      .table-container {
        // display: flex;
        // flex-direction: column;
        // overflow: auto;
        display: block;
        width: 100%;
        height: 100%;
        overflow: auto;
        // border-radius: 6px;
        position: relative;
        background-color: #fff;
        // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.28);
        .table-head,
        .tbl-row {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 0 35px;
        }
        .tbl-head-section {
          width: 100%;
          // background-color: #dbebfc;
          background-color: #fff;
          border-bottom: 1px solid #e4e4e4;
          position: sticky;
          top: 0;
          z-index: 1;
          // color: #555555;
          .table-head {
            height: 45px;
          }
          .heading {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            // padding-right: 35px;
            .text {
              position: relative;
              cursor: pointer;
              .icon {
                display: none;
              }
              &:hover {
                .icon {
                  display: inline;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  margin-left: 8px;
                  width: 12px;
                  height: 8px;
                }
                .arrow-down {
                  background-image: url("../images/arrow-down.svg");
                }
                .arrow-up {
                  background-image: url("../images/arrow-up.svg");
                }
              }
            }
          }
        }
        .tbl-body-section {
          display: block;
          .accordion__item {
            // border-bottom: 1px solid #e4e4e4;
            background-color: #fff;

            &:last-child {
              border-bottom: none;
            }
            &:nth-child(even) {
              background-color: #f4f4f4;
            }

            .tbl-row {
              height: 45px;
              // &.sub-tbl-row {
              //   background-color: #fff;
              // }

              .inner-wrapper {
                // padding-right: 35px;
                position: relative;
                .options-view {
                  position: absolute;
                  width: 200px;
                  min-height: 85px;
                  background-color: #fff;
                  border: 1px solid #000000;
                  box-sizing: border-box;
                  border-radius: 10px;
                  padding: 16px;
                  top: 25px;
                  right: -10px;
                  z-index: 1;
                  .option {
                    font-size: 16px;
                    line-height: 18px;
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #e4e4e4;
                    font-weight: 600;
                    cursor: pointer;
                    &:last-child {
                      margin-bottom: 0;
                      border: none;
                      padding: 0;
                    }
                  }
                }
              }
              .heading {
                font-size: 16px;
                line-height: 24px;
                padding-right: 35px;
              }
              .three-dot-icon {
                width: 4px;
                height: 18px;
                background-image: url("../images/three-dots.svg");
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
              }
              .sub-heading {
                font-size: 16px;
                line-height: 24px;
                color: #707070;
                padding-right: 35px;
              }
            }
          }
        }

        // Sales Table
        .table-head {
          .heading {
            &:nth-child(1) {
              width: 24%;
            }
            &:nth-child(2),
            &:nth-child(5) {
              width: 12%;
            }
            &:nth-child(3),
            &:nth-child(4) {
              width: 16%;
            }
            &:nth-child(6) {
              width: 18%;
            }
            &:nth-child(7) {
              width: 2%;
            }
          }
        }
        .tbl-body-section {
          .tbl-row {
            .inner-wrapper {
              &:nth-child(1) {
                width: 24%;
                cursor: pointer;
              }
              &:nth-child(2) {
                cursor: pointer;
                .icon {
                  display: inline;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  margin-left: 8px;
                  width: 12px;
                  height: 8px;
                }
                .arrow-down {
                  background-image: url("../images/arrow-down.svg");
                }
                .arrow-up {
                  background-image: url("../images/arrow-up.svg");
                }
              }
              &:nth-child(2),
              &:nth-child(5) {
                width: 12%;
              }
              &:nth-child(3),
              &:nth-child(4) {
                width: 16%;
              }
              &:nth-child(6) {
                cursor: pointer;
                width: 18%;
              }
              &:nth-child(7) {
                width: 2%;
              }
            }
          }
        }
        // Ends

        .loader-wrapper {
          height: 60px;
          .loader-icon {
            width: 60px;
          }
        }
        .no-test-case-found {
          margin: 20px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #707070;
        }
      }
    }
  }
}
