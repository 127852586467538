.summary-main-container {
  .header-container {
    position: relative;
    z-index: 11;
    .inner-wrapper {
      // box-shadow: none;
      position: fixed;
      z-index: 1;
      background-color: white;
      width: 100%;
      .header-links-wrapper {
        display: none;
      }
      @media only screen and (min-width: 1200px) {
        .header-links-wrapper {
          display: flex;
          width: 400px;
          justify-content: space-between;
          .link {
            position: relative;
            cursor: pointer;
            &.active {
              .vertical-line {
                width: 100%;
                height: 5px;
                background-color: #006f44;
                position: absolute;
                bottom: -25px;
              }
            }
          }
        }
        .header-icons {
          margin-left: 20%;
        }
      }
    }
  }
  .middle-area {
    padding: 70px 0;
    .banner-container {
      .image-wrapper {
        height: 250px;
        background-attachment: unset;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 30px;
        &::after {
          border: none;
        }
        // &.Foods {
        //   background-image: url("../../assets/food-bg.png");
        // }
        // &.Tips {
        //   background-image: url("../../assets/tips-bg.png");
        //   background-position: 0 -120px;
        // }
        // &.Products {
        //   background-image: url("../../assets/product.bg.png");
        //   background-position: 0 -540px;
        // }
      }
      .summary-detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 35%;
        margin: auto;
        margin-bottom: 50px;
        font-size: 16px;
        line-height: 24px;
        @include media_query(M) {
          width: 90%;
          margin-bottom: 20px;
        }
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          width: 60%;
        }
      }
    }
    .summary-middle-area {
      .inner-wrapper {
        .left-wrapper {
          position: sticky;
          top: 100px;
          @include media_query(M) {
            border: 1px solid #e4e4e4;
            box-sizing: border-box;
            border-radius: 10px;
            margin: 50px 0;
            padding: 16px;
            position: relative;
            top: auto;
          }
          @media only screen and (min-width: 768px) and (orientation: portrait) {
            margin: 0;
            border: none;
            padding: 0 15px;
            position: sticky;
            top: 100px;
          }
          .filter-wrapper {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e4e4e4;
            padding-bottom: 8px;
            margin-bottom: 25px;
            position: relative;
            @include media_query(M) {
              border: none;
              padding: 0;
              margin-bottom: 0;
            }
            @media only screen and (min-width: 768px) and (orientation: portrait) {
              border-bottom: 1px solid #e4e4e4;
              padding-bottom: 8px;
              margin-bottom: 25px;
            }
            .label {
              font-weight: 600;
              font-size: 21px;
              line-height: 24px;
              padding-left: 20px;
            }
            // &.active {
            @include media_query(M) {
              // &::after {
              //   content: "";
              //   border: solid #006f44;
              //   border-width: 0 2px 2px 0;
              //   display: inline-block;
              //   padding: 5px;
              //   // transform: rotate(45deg);
              //   transform: rotate(-135deg);
              //   position: absolute;
              //   justify-content: left;
              //   right: 14px;
              //   top: 4px;
              // }
              .label {
                color: $darkBlack;
                //color: $darkGreen;
                font-size: 16px;
              }
            }
            // }
          }
          .switch {
            margin-bottom: 25px;
            height: 36px;
            @include media_query(M) {
              position: absolute;
              top: -50px;
            }
            @media only screen and (min-width: 768px) and (orientation: portrait) {
              position: relative;
              top: auto;
            }
            .toggle-container {
              width: 290px;
              height: 36px;
              border-radius: 27px;
              background-color: transparent !important;
              border: 2px solid #006f44 !important;
              padding: 0;
              &::before {
                width: 50%;
                height: 100%;
                top: auto;
                left: 0;
                bottom: 0;
                right: auto;
              }
              .right-box-container,
              .left-box-container {
                width: 50%;
                padding: 4px 0;
                text-align: center;
                font-weight: 600;
              }
            }
          }
          .best-for-you-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            @include media_query(M) {
              margin-top: 20px;
            }
            .details {
              .label {
                padding-left: 12px;
                font-size: 16px;
                line-height: 18px;
              }
            }
            .switch {
              width: 48px;
              height: 24px;
              position: relative;
              top: 0;
              margin: auto;
              // @include media_query(M) {
              //   position: relative;
              //   top: 0;
              //   margin: auto;
              // }
              .toggle-container {
                width: 48px;
                height: 24px;
                // background-color: #006f44;
                background-color: #000 !important;
                border: 1px solid #000 !important;
                &::before {
                  background: #fff;
                }
                .left-box-container,
                .right-box-container {
                  width: 50%;
                  padding: 4px 0;
                  text-align: center;
                  font-weight: 600;
                }

                .left-box-container {
                  position: relative;
                  &::before {
                    content: "";
                    position: absolute;
                    left: 10px;
                    top: 4px;
                    width: 7px;
                    height: 13px;
                    border: solid white;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                  }
                }
                .right-box-container {
                  position: relative;
                  &::before {
                    content: "X";
                    position: absolute;
                    right: 9px;
                    top: 5px;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 700;
                    // background: #ffffff;
                  }
                }
              }
              input:checked + .slider {
                background-color: #006f44 !important;
                border: none !important;
              }
            }
          }
          .wellness-area-wrapper {
            @include media_query(M) {
              margin-top: 25px;
            }
            .label {
              font-weight: 600;
              font-size: 16px;
              line-height: 18px;
              border-bottom: 1px solid #e4e4e4;
              padding-bottom: 8px;
              margin-bottom: 15px;
            }
            .wellness-area-lists {
              .list {
                display: flex;
                /* align-items: center; */
                position: relative;
                margin-bottom: 25px;
                .filter-icon {
                  height: 20px;
                  width: 18px;
                }
                .list-label {
                  padding-left: 20px;
                  font-size: 16px;
                  line-height: 18px;
                }
                .checkbox {
                  right: 0;
                  position: absolute;
                }
              }
            }
          }
        }
        .right-wrapper {
          @include media_query(M) {
            padding: 0;
          }
          .all-food-wrapper {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e4e4e4;
            padding-bottom: 8px;
            margin-bottom: 25px;
            .label {
              font-weight: 600;
              font-size: 21px;
              line-height: 24px;
              padding-left: 20px;
            }
          }
          .food-card-wrapper {
            .inner-wrapper {
              @include media_query(M) {
                padding: 0;
              }
              @media only screen and (min-width: 768px) and (orientation: portrait) {
                padding: 0 15px;
              }
              .cards-area {
                margin: 0;
                .cards-wrapper {
                  margin-bottom: 15px;
                  @include media_query(M) {
                    padding: 0;
                  }
                  @media only screen and (min-width: 768px) and (orientation: portrait) {
                    padding: 0 15px;
                  }
                  .white-cards {
                    height: 100%;
                    .inner-wrapper {
                      @media only screen and (min-width: 768px) and (orientation: portrait) {
                        margin: 0 -15px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//for product summary cards
.product-summary-wrapper {
  .inner-wrapper {
    @include media_query(M) {
      padding: 0;
      margin: 0;
    }
    .product-cards-wrapper {
      margin-bottom: 25px;
      @include media_query(M) {
        padding: 0;
      }
      .white-cards {
        height: 100%;
        .inner-wrapper {
          padding: 20px;
          height: 100%;
          .top-wrapper {
            margin-bottom: 40px;
            padding-top: 20px;
            .slider-wrapper {
              width: 100%;
              .silder-images-wrapper {
                width: 100%;
                .slick-slider {
                  .slick-prev,
                  .slick-next {
                    height: 21px;
                    width: 12px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    z-index: 2;
                    &::before {
                      display: none;
                    }
                  }
                  .slick-prev {
                    background-image: url("../images/slider-prev.svg");
                    margin-left: 10px;
                    left: 0;
                  }
                  .slick-next {
                    background-image: url("../images/slider-next.svg");
                    margin-right: 10px;
                    right: 0;
                  }
                  .slick-list {
                    .slider-main-img {
                      min-width: 100%;
                      min-height: 100%;
                      display: flex !important;
                      justify-content: center;
                      width: 220px;
                      height: 220px;
                      .slider-img {
                        background-repeat: no-repeat;
                        background-size: contain;
                      }
                    }
                  }
                  .slick-dots {
                    display: flex;
                    top: auto;
                    bottom: -25px;
                    width: 100%;
                    li {
                      margin-bottom: 0px;
                      height: 20px;
                      width: 20px;
                      button {
                        &::before {
                          content: "";
                          background: #e4e4e4;
                          width: 7px;
                          height: 7px;
                          border-radius: 50%;
                          opacity: 1;
                        }
                      }
                    }
                    .slick-active {
                      button {
                        &::before {
                          background: #66a98f;
                        }
                      }
                    }
                  }
                }
                .fallback-main-img {
                  width: 100%;
                  height: 100%;
                  // display: flex !important;
                  // justify-content: center;
                  // padding: 20px 30px;
                  // @include media_query(M) {
                  //   padding: 0;
                  // }
                  .fallback-img {
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
          .bottom-wrapper {
            .product-detail-wrapper {
              .product-detail {
                width: 70%;
                float: left;
                @include media_query(M) {
                  // width: 250px;
                }
                .need-state-icons {
                  margin-bottom: 20px;
                  width: 100px;
                  display: flex;
                  justify-content: space-between;
                  .icon-list {
                    width: 18px;
                    height: 18px;
                    //background-image: url("../images/moon.svg");
                    //For this color #6D6D6D
                    filter: invert(45%) sepia(0%) saturate(0%)
                      hue-rotate(244deg) brightness(93%) contrast(84%);
                  }
                  position: absolute;
                  top: 10px;
                }
                .product-name {
                  font-weight: bold;
                  // font-size: 32px;
                  // line-height: 36px;
                  // margin-bottom: 15px;
                  // width: 90%;
                  font-size: 21px;
                  line-height: 24px;
                  margin-bottom: 10px;
                }
                .product-recommended {
                  font-size: 16px;
                  line-height: 24px;
                }
                .rating-star-wrapper {
                  font-size: 16px;
                  line-height: 24px;
                  margin-bottom: 8px;
                  .label {
                    margin-left: 8px;
                  }
                }
              }
              .product-price {
                font-weight: bold;
                font-size: 21px;
                line-height: 26px;
                float: right;
              }
            }
            .product-description-wrapper {
              // margin-bottom: 30px;
              .descriptions {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 8px;
              }
              .learn-more {
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                color: #006f44;
                cursor: pointer;
                position: relative;
                &::after {
                  content: "";
                  width: 7px;
                  height: 11px;
                  background-image: url("../images/green-arrow.svg");
                  background-repeat: no-repeat;
                  background-size: contain;
                  display: inline-block;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  margin-left: 5px;
                }
              }
            }
          }
        }
        .add-to-cart-wrapper {
          position: relative;
          bottom: 60px;
          padding: 0 20px;
          .btn-area {
            width: auto;
            max-width: 185px;
            height: 48px;
            white-space: nowrap;
            &.quantity-btn-area {
              width: 165px;
              @include media_query(M) {
                width: 185px;
              }
            }
            .btn-icon {
              //  text-align: right;
              padding: 0 45px;
              font-weight: bold;
              font-size: 14px;
              line-height: 18px;
              &::after {
                content: "";
                background-image: url("../images/cart.svg");
                left: 25px;
                right: auto;
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(17deg)
                  brightness(111%) contrast(101%);
              }
            }
            @include media_query(M) {
              max-width: 170px;
            }
          }
        }
      }
    }
  }
  &.other-products {
    .inner-wrapper {
      .product-cards-wrapper {
        .top-wrapper {
          .slider-wrapper {
            .silder-images-wrapper {
              .slick-slider {
                .slick-prev {
                  margin-left: 0px !important;
                }
                .slick-next {
                  margin-right: 0px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
