.amw-share-social__btn {
  padding: 0;
}

.amw-share-social__btn-text {
  font-size: 0.875rem;
  font-weight: 700;
  font-family: "gt-walsheim", sans-serif;
  margin: 0 0 0 8px;
}

.amw-share-social__icon {
  color: #2c2c2c;
  font-size: 1.875rem;
  vertical-align: middle;
}

.share-buttons{
  display: flex;
  flex-wrap: wrap;
  margin-top:2em;
}

.share-button-box{
  flex: 50%;
}

@media (max-width: 800px) {
  .share-button-box {
    flex: 100%;
  }
}

.icon-envelope:before {content: "\E90D";}
.icon-messenger:before{content:"\e90c";}
.icon-whatsapp:before{content:"\e90b";}
.icon-sms:before {content: "\f03c";}
.icon-dots-three-horizontal:before {content: "\ecda";}




.share-button-box [class^="icon-"],
.share-button-box [class*=" icon-"] {
  font-family: 'AmwayIcons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.share-button-box input[type="submit"],
.share-button-box input[type="reset"],
.share-button-box input[type="button"],
.share-button-box button,
.share-button-box .btn {
  background: none;
  border: 0;
  box-sizing: border-box;
  color: #2c2c2c;
  font-family: 'GT Walsheim Pro', 'Source Sans Pro MC';
  font-size: 1.125em;
  font-weight: 600;
  height: 2.65em;
  line-height: normal;
  overflow: visible;
  padding-top: .6em;
}