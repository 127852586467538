.home-container {
  a.skip-link {
    left: 0%;
    position: absolute;
    top: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;
    border: #006f44;
    text-decoration: none;
  }
  a.skip-link:focus,
  a.skip-link:active {
    background-color: red;
    color: #ffffff;
    padding: 10px;
    position: absolute;
    text-transform: capitalize;
    z-index: 10100;
    left: 0;
    top: auto;
    width: 15%;
    height: auto;
    overflow: auto;
    margin: 0px;
    text-align: center;
    font-weight: 700;
  }
  .header-container {
    position: relative;
    .inner-wrapper {
      position: fixed;
      width: 100%;
      z-index: 1;
      background-color: #ffffff;
      .header-links-wrapper {
        display: none;
      }
      @media only screen and (min-width: 1200px) {
        .header-links-wrapper {
          display: flex;
          width: 400px;
          justify-content: space-between;
          .link {
            position: relative;
            cursor: pointer;
            &.active {
              .vertical-line {
                width: 100%;
                height: 5px;
                background-color: #006f44;
                position: absolute;
                bottom: -25px;
              }
            }
          }
        }
        .header-icons {
          margin-left: 20%;
        }
      }
      // .hamburger-container {
      //   .hamburger-wrapper {
      //     .visible-myoption {
      //       display: none;
      //     }
      //   }
      // }
    }
    .banner-bg-container {
      position: relative;
      padding-top: 70px;
      .banner-bg-img {
        width: 100%;
        height: 600px;
        //background-image: url("../../assets/home-bg.png");
        background-size: cover;
        background-position: center;
        @include media_query(M) {
          //background-image: url("../../assets/mb-home-bg.png");
          height: 250px;
        }
        // For tablet Portrait
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          //background-image: url("../../assets/home-bg.png");

          height: 600px;
        }
      }
      .banner-title-wrapper {
        width: 650px;
        min-height: 190px;
        position: relative;
        margin: auto;
        margin-top: -50px;
        background: #ffffff;
        border: 1px solid #cac0ac;
        box-sizing: border-box;
        box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 8%);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 25px;
        margin-bottom: 20px;
        text-align: center;
        @include media_query(M) {
          width: 343px;
          // padding: 25px;
        }
        // For tablet Portrait
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          width: 650px;
        }
        .heading {
          font-weight: 600;
          font-size: 32px;
          line-height: 36px;
          margin-bottom: 15px;
          @include media_query(M) {
            font-size: 28px;
            line-height: 32px;
          }
        }
        .details {
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          margin-bottom: 20px;
          @include media_query(M) {
            font-size: 14px;
            line-height: 18px;
          }
        }
        .take-survey-btn {
          .btn-area {
            @include media_query(M) {
              min-width: 171px;
            }
          }
        }
      }
      .banner-details-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        .vertical-line {
          border-left: 2px solid #66a98f;
          height: 64px;
          margin-bottom: 35px;
        }
        .works-wrapper {
          text-align: center;
          width: 50%;
          padding: 0 123px;
          margin-bottom: 80px;
          @include media_query(M) {
            width: 100%;
            padding: 0 25px;
          }
          // For tablet Portrait
          @media only screen and (min-width: 768px) and (orientation: portrait) {
            width: 50%;
          }
          .title {
            font-weight: 600;
            font-size: 32px;
            line-height: 36px;
          }
          .details {
            font-size: 16px;
            line-height: 24px;
          }
        }
        .steps-wrapper {
          display: flex;
          position: relative;
          margin-bottom: 30px;
          width: 100%;
          justify-content: space-around;
          @include media_query(M) {
            flex-direction: column;
            align-items: center;
            margin-bottom: 0px;
          }
          .step {
            position: relative;
            display: flex;
            align-items: center;
            @include media_query(M) {
              margin-bottom: 60px;
            }
            .count {
              font-weight: bold;
              font-size: 100px;
              line-height: 128px;
              color: #e6f1ed;
              position: absolute;
              bottom: 0;
              z-index: -1;
            }
            .description {
              width: 265px;
              text-align: center;
              font-size: 16px;
              line-height: 24px;
              /* position: absolute; */
              /* bottom: 0; */
              /* margin: auto; */
              padding-left: 18px;
            }
          }
        }
      }
    }
  }
  .middle-area {
    margin-bottom: 60px;
    .your-wellness-container {
      background: #f4f4f4;
      padding: 48px;
      @include media_query(M) {
        padding: 30px 0;
        padding-bottom: 200px;
      }
      // For tablet Portrait
      @media only screen and (min-width: 768px) and (orientation: portrait) {
        padding-bottom: 30px;
      }
      .inner-wrapper {
        display: flex;
        align-items: center;
        @include media_query(M) {
          display: block;
          position: relative;
        }
        // For tablet Portrait
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          display: flex;
        }
        .left-container {
          // display: flex;
          // align-items: center;
          // justify-content: center;
          @include media_query(M) {
            margin-bottom: 50px;
            position: unset;
          }
          .details-area {
            .heading {
              font-weight: 600;
              font-size: 32px;
              line-height: 36px;
              text-align: center;
              padding: 0 130px;
              margin-bottom: 30px;
              // justify-content: center;
              @include media_query(M) {
                padding: 0;
              }
              // For tablet Portrait
              @media only screen and (min-width: 768px) and (orientation: portrait) {
                padding: 0;
              }
            }
            .detail {
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              padding: 0 115px;
              margin-bottom: 50px;
              @include media_query(M) {
                padding: 0;
              }
            }
            .btn-wrapper {
              display: flex;
              justify-content: center;
              @include media_query(M) {
                display: block;
                position: absolute;
                bottom: -200px;
                width: 100%;
                padding: 0 30px;
                left: 0;
                right: 0;
                margin: auto;
              }
              // For tablet Portrait
              @media only screen and (min-width: 768px) and (orientation: portrait) {
                display: flex;
                position: relative;
                padding: 0;
                bottom: 0;
              }
              .take-survey-btn {
                margin-right: 30px;
                margin-bottom: 0px;

                @include media_query(M) {
                  margin-bottom: 30px;
                  margin-right: 0px;
                }
                // For tablet Portrait
                @media only screen and (min-width: 768px) and (orientation: portrait) {
                  margin-right: 20px;
                  margin-bottom: 0px;
                }
              }
            }
          }
        }
        .right-container {
          display: flex;
          // justify-content: center;
          flex-direction: column;
          align-items: center;
          .profile-area {
            width: 400px;
            position: relative;
            @include media_query(M) {
              width: 340px;
            }
            .profile-card {
              width: 400px;
              min-height: 490px;
              background: #ffffff;
              border: 1px solid #e4e4e4;
              box-sizing: border-box;
              // box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 10%);
              border-radius: 10px;
              position: relative;
              margin-top: 50px;
              .profile-img {
                width: 130px;
                height: 130px;
                border-radius: 50%;
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                top: -50px;
                .user-img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                }
              }
              .profile-detail-wrapper {
                padding: 0 25px;
                padding-top: 110px;
                padding-bottom: 25px;
                .profile-name {
                  font-weight: 600;
                  font-size: 32px;
                  line-height: 36px;
                  text-align: center;
                  margin-bottom: 5px;
                }
                .profile-details {
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  // padding: 0 50px;
                  margin-bottom: 30px;
                }
                .profile-lists {
                  .list {
                    display: flex;
                    align-items: center;
                    margin-bottom: 30px;
                    .list-icon {
                      width: 20px;
                      height: 20px;
                      margin-right: 13px;
                      img {
                        width: 20px;
                        height: 20px;
                        max-width: none;
                      }
                    }
                  }
                }
                .disclaimer-wrapper {
                  font-size: 12px;
                  .point {
                    margin-bottom: 4px;
                  }
                }
              }
            }
            .slick-slider {
              .slick-prev,
              .slick-next {
                height: 21px;
                width: 12px;
                background-repeat: no-repeat;
                background-size: contain;
                z-index: 11;
                &::before {
                  display: none;
                }
                @include media_query(M) {
                  display: none !important;
                }
              }
              .slick-prev {
                background-image: url("../images/slider-prev.svg");
              }
              .slick-next {
                background-image: url("../images/slider-next.svg");
              }
              .slick-list {
                // overflow: visible;
                // overflow-x: clip;
                .slider-main-img {
                  min-width: 100%;
                  min-height: 100%;
                  display: flex !important;
                  justify-content: center;
                  width: 220px;
                  height: 220px;
                  .slider-img {
                    background-repeat: no-repeat;
                    background-size: contain;
                  }
                }
              }
              .slick-dots {
                display: flex;
                top: auto;
                bottom: -25px;
                width: 100%;
                li {
                  margin-bottom: 0px;
                  height: 20px;
                  width: 20px;
                  button {
                    &::before {
                      content: "";
                      background: #e4e4e4;
                      width: 7px;
                      height: 7px;
                      border-radius: 50%;
                      opacity: 1;
                    }
                  }
                }
                .slick-active {
                  button {
                    &::before {
                      background: #66a98f;
                    }
                  }
                }
              }
            }
          }
          .outer-disclaimer {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            margin-top: 30px;
          }
        }
      }
    }
    .you-got-wrapper {
      width: 100%;
      height: 700px;
      //background-image: url("../../assets/you-got-bg.png");
      background-size: cover;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 50px;
      background-position: center;
      @include media_query(M) {
        padding: 30px;
      }
      // For tablet Portrait
      @media only screen and (min-width: 768px) and (orientation: portrait) {
        height: 500px;
      }
      .heading {
        font-weight: 600;
        font-size: 32px;
        line-height: 36px;
        text-align: center;
        margin-bottom: 10px;
      }
      .details {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        width: 420px;
        @include media_query(M) {
          width: 100%;
        }
      }
    }
    .our-nutritionist-container {
      width: 100%;
      min-height: 700px;
      //background-image: url("../../assets/diana-bg.png");
      // background-size: cover;
      background: #f4f4f4;
      display: flex;
      // flex-direction: column;
      align-items: center;
      // justify-content: center;
      // padding: 50px 80px;
      padding: 50px;
      @include media_query(M) {
        //background-image: url("../../assets/mb-diana-bg.png");
        padding: 16px;
        flex-direction: unset;
        align-items: unset;
        justify-content: unset;
      }
      // For tablet Portrait
      @media only screen and (min-width: 768px) and (orientation: portrait) {
        //background-image: url("../../assets/diana-bg.png");
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        height: auto;
      }
      .inner-wrapper {
        // width: 430px;
        // text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @include media_query(M) {
          flex-direction: column;
          position: relative;
        }
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          flex-direction: row;
        }
        .left-wrapper {
          width: 60%;
          padding: 0 30px;
          @include media_query(M) {
            width: 100%;
            padding: 0;
            padding-top: 120px;
          }
          @media only screen and (min-width: 768px) and (orientation: portrait) {
            width: 60%;
            padding: 0 5px;
          }
          .our-scientists-contaier {
            @include media_query(M) {
              margin: 0;
              padding: 0;
            }
            @media only screen and (min-width: 768px) and (orientation: portrait) {
              margin: auto;
              padding: auto;
            }
            .scientists-wrapper {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              margin-bottom: 50px;
              margin-top: 50px;
              .profile-img {
                width: 130px;
                height: 130px;
                margin-bottom: 10px;
                @include media_query(M) {
                  width: 100px;
                  height: 100px;
                }
                .scientists-img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  background-repeat: no-repeat;
                  background-size: contain;
                }
              }
              .name {
                font-weight: 600;
                font-size: 21px;
                line-height: 24px;
              }
              .designation {
                font-size: 16px;
                line-height: 24px;
                @include media_query(M) {
                  font-size: 14px;
                  line-height: 18px;
                }
              }
            }
          }
        }
        .right-wrapper {
          // width: 430px;
          width: 40%;
          text-align: center;
          padding: 0 50px;
          @include media_query(M) {
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
          }
          @media only screen and (min-width: 768px) and (orientation: portrait) {
            width: 40%;
            display: block;
          }
          .details-wrapper {
            @include media_query(M) {
              position: absolute;
              top: 0;
              text-align: center;
              width: 100%;
              @media only screen and (min-width: 768px) and (orientation: portrait) {
                position: relative;
              }
            }
            .heading {
              font-weight: 600;
              font-size: 32px;
              line-height: 36px;
              margin-bottom: 20px;
            }
            .sub-title {
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 80px;
            }
          }
          .nutritionist-details {
            width: 100%;
            min-height: 150px;
            /* left: 846px; */
            background: #ffffff;
            border: 1px solid #e4e4e4;
            box-sizing: border-box;
            box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 8%);
            border-radius: 10px;
            padding: 24px 20px;
            text-align: center;
            @include media_query(M) {
              // height: 200px;
              padding: 20px;
            }
            .name {
              font-weight: 600;
              font-size: 32px;
              line-height: 36px;
              margin-bottom: 15px;
              @include media_query(M) {
                font-size: 28px;
                line-height: 32px;
                margin-bottom: 8px;
              }
            }
            .designation {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 20px;
              @include media_query(M) {
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 12px;
              }
            }
            .description {
              font-size: 16px;
              line-height: 24px;
              @include media_query(M) {
                font-size: 14px;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
    .journey-start-container {
      width: 100%;
      height: 700px;
      //background-image: url("../../assets/wellness-journey-bg.png");
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 50px 100px;
      margin-bottom: 50px;
      @include media_query(M) {
        padding: 40px 16px;
        //background-image: url("../../assets/mb-wellness-journey-bg.png");
        justify-content: unset;
      }
      // For tablet Portrait
      @media only screen and (min-width: 768px) and (orientation: portrait) {
        //background-image: url("../../assets/wellness-journey-bg.png");
        justify-content: center;
        height: 410px;
      }
      .inner-wrapper {
        width: 380px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include media_query(M) {
          width: 100%;
        }
        // For tablet Portrait
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          width: 380px;
        }
        .heading {
          font-weight: 600;
          font-size: 32px;
          line-height: 36px;
          color: #ffffff;
          margin-bottom: 30px;
        }
        .sub-title {
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
          margin-bottom: 40px;
        }
      }
    }
    .explore-more-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .heading {
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 10px;
      }
      .details {
        width: 265px;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 40px;
      }
      .company-logo {
        display: flex;
        width: 300px;
        align-items: center;
        justify-content: space-between;
        .nutrilite-logo {
          width: 150px;
          height: 25px;
          //background-image: url("../../assets/nutrilite.png");
        }
        .xs-logo {
          width: 94px;
          height: 50px;
          //background-image: url("../../assets/xs.png");
        }
      }
    }
  }
  .footer-area {
    height: 72px;
    .instruction {
      display: none;
    }
  }
}
