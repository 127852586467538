//custom-dropdown container
.dropdown-container {
  width: 100%;
  margin-bottom: 30px;
  .dropdown-label {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
  .dropdown-sub-label {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 6px;
  }
  .option-wrapper {
    position: relative;
    width: 340px;
    @include media_query(M) {
      width: 100%;
      }
      //For tablet Portrait
      @media only screen and (min-width: 768px) and (orientation: portrait) {
      width: 340px
      }
    &.error-wrapper {
      margin-bottom: 12px;
    }
  }
  .error-msg {
    // font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #d91734;
  }
  .dropdown-title {
    margin-bottom: 12px;
  }

  //arrow icon
  .arrowDown {
    @include arrow;
    position: absolute;
    bottom: 19px;
    -webkit-transform: rotate(45deg);
  }
  .arrowUp {
    @include arrow;
    bottom: 12px;
    -webkit-transform: rotate(-135deg);
  }
  //dropdown option fileds
  .dropdown-options {
    padding: 2px 12px;
    max-height: 194px;
    margin-top: 5px;
    left: 0px;
    right: 0px;
    background: $white;
    // box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
    position: absolute;
    display: block;
    z-index: 1;
    cursor: pointer;
    overflow-y: auto;
    border: 2px solid #2c2c2c;
    box-sizing: border-box;
    border-radius: 10px;
    &::-webkit-scrollbar {
      display: block;
      width: 5px;
      height: 100%;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      height: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      position: absolute;
      height: 50px;
      right: 4px;
      top: 6px;

      background: #d8d8d8;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-track-piece:end {
      // background: transparent;
      margin-bottom: 10px;
    }

    &::-webkit-scrollbar-track-piece:start {
      // background: transparent;
      margin-top: 10px;
    }
    .dropdown-value {
      // border-bottom: 0.1px solid rgba(214, 214, 214, 0.35);
      height: 38px;
      text-align: left;
      line-height: 30px;
      // font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
    }
  }
  &.dropdown-with-checkbox {
    margin-bottom: 10px;
    .search-icon {
      position: absolute;
      top: 0;
      right: 10px;
      bottom: 0;
      margin: auto;
    }
    .dropdown-options {
      .dropdown-value-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 38px;
        .dropdown-value {
          height: 0px;
          line-height: 0px;
        }
        &.active {
          .dropdown-value {
            color: $darkGreen;
            font-weight: 700;
          }
        }
      }
    }
  }
  &.disable {
    .dropdown-label {
      color: #707070;
    }
    .option-wrapper {
      pointer-events: none;
      .input-wrapper {
        .custom-input-wrapper {
          .custom-input {
            border: 2px solid #707070;
            background: #f4f4f4;
            pointer-events: none;
          }
        }
      }
    }
  }
}
