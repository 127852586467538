//## Font colors for use across.

$white: #fff;
$darkGreen: #006f44; //#005c50;
$darkGray: #414141;
$darkBlack: #2c2c2c;
$black: #000;
$gray-1: #eee;
$gray-2: #9b9b9b;
$blue: #6c8ced;
$red-text: #fc0446;
$red-1: #f70038;

$border-color: #979797;
$gray-txt: #b7b7b7;
$tab-active-color: #6592fd;
$tab-text-color: #404e54;
$bg-color: #f7f9ff;
$horizontal-line-color: #dedede;
