/** Temeraire **/

/* Temeraire-Regular */
// @font-face {
//   font-family: "Temeraire";
//   src: url("../../fonts/Temeraire-Regular.eot");
//   src: url("../../fonts/Temeraire-Regular.woff2") format("woff2"),
//     url("../../fonts/Temeraire-Regular.woff") format("woff");
//   font-weight: 400;
//   font-style: normal;
// }

// /* Temeraire-Bold */
// @font-face {
//   font-family: "Temeraire";
//   src: url("../../fonts/Temeraire-Bold.eot");
//   src: url("../../fonts/Temeraire-Bold.woff2") format("woff2"),
//     url("../../fonts/Temeraire-Bold.woff") format("woff");
//   font-weight: 600;
//   font-style: normal;
// }

// /* Temeraire-Italic */
// @font-face {
//   font-family: "Temeraire";
//   src: url("../../fonts/Temeraire-Italic.eot");
//   src: url("../../fonts/Temeraire-Italic.woff2") format("woff2"),
//     url("../../fonts/Temeraire-Italic.woff") format("woff");
//   font-weight: 150;
//   font-style: normal;
// }

/** Montserrat **/

/* Montserrat-Regular */
// @font-face {
//   font-family: "Montserrat";
//   src: url("../../fonts/Montserrat-Regular.ttf") format("truetype");
//   font-weight: 400;
//   font-style: normal;
// }

// /* Montserrat-Medium */
// @font-face {
//   font-family: "Montserrat";
//   src: url("../../fonts/Montserrat-Medium.ttf") format("truetype");
//   font-weight: 500;
//   font-style: normal;
// }

// /* Montserrat-Semi-bold */
// @font-face {
//   font-family: "Montserrat";
//   src: url("../../fonts/Montserrat-SemiBold.ttf") format("truetype");
//   font-weight: 600;
//   font-style: normal;
// }

/** GT-Walsheim **/

/* GT-Walsheim-Regular */
@font-face {
  font-family: "gt-walsheim";
  src: url("../../fonts/GT-Walsheim-Pro-Regular.eot");
  src: url("../../fonts/GT-Walsheim-Pro-Regular.woff2") format("woff2"),
    url("../../fonts/GT-Walsheim-Pro-Regular.woff") format("woff"),
    url("../../fonts/GT-Walsheim-Pro-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* GT-Walsheim-Medium */
@font-face {
  font-family: "gt-walsheim";
  src: url("../../fonts/GT-Walsheim-Pro-Medium.eot");
  src: url("../../fonts/GT-Walsheim-Pro-Medium.woff2") format("woff2"),
    url("../../fonts/GT-Walsheim-Pro-Medium.woff") format("woff"),
    url("../../fonts/GT-Walsheim-Pro-Medium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* GT-Walsheim-Bold */
@font-face {
  font-family: "gt-walsheim";
  src: url("../../fonts/GT-Walsheim-Pro-Bold.eot");
  src: url("../../fonts/GT-Walsheim-Pro-Bold.woff2") format("woff2"),
    url("../../fonts/GT-Walsheim-Pro-Bold.woff") format("woff"),
    url("../../fonts/GT-Walsheim-Pro-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* AmwayIcons */
@font-face {
  font-family: 'AmwayIcons';
  src: url('../../fonts/AmwayIcons.woff?version=1.52.0.16') format('woff2'),
       url('../../fonts/AmwayIcons.woff?version=1.52.0.16') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}