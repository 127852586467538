.pick-survey-wrapper {
  height: 100%;
  position: relative;
  position: fixed;
  background: #ffffff;
  box-shadow: 4px 0px 5px rgba(0, 0, 0, 0.15);
  z-index: 111;
  width: 590px;
  @include media_query(M) {
    width: 100%;
  }
  // For tablet Portrait
  @media only screen and (min-width: 768px) and (orientation: portrait) {
    width: 590px;
  }
  .pick-survey-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 25px 28px;
    background: $white;
    box-shadow: 0 0 4px 2px rgba(0, 92, 80, 0.1);
    width: 100%;
    z-index: 1;
    .header-title {
      // font-family: "Temeraire";
      font-style: normal;
      font-size: 24px;
      line-height: 22px;
      font-weight: 600;
      padding-left: 10px;
      // color: $darkGreen;
      @include media_query(M) {
        font-size: 18px;
      }
    }
    .header-icons {
      width: 80px;
      display: flex;
      justify-content: space-between;
      // @include media_query(M) {
      //   width: 80px;
      // }
      .icon-wrapper {
        position: relative;
        .header-options {
          position: absolute;
          top: 47px;
          right: 0;
          height: 50px;
          width: 100px;
          margin: auto;
          background: #ffffff;
          box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.15);
          border-radius: 0px 0px 10px 10px;
          display: flex;
          align-items: center;
          padding: 15px;
          cursor: pointer;
          z-index: 111;
          .label {
            // font-family: "Montserrat";
            // font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            // color: #6d6d6d;
            text-align: center;
          }
          &::before {
            content: "";
            width: 5px;
            height: 5px;
            background-color: antiquewhite;
            position: absolute;
            right: 7px;
            top: -20px;
            background: $darkGreen;
            border-radius: 50%;
          }
        }
      }
    }
    @include media_query(M) {
      padding: 16px;
    }
  }
  .middle-area {
    height: calc(100% - 70px);
    overflow: auto;
    padding-top: 0;
    .main-bg-wrapper {
      position: relative;
      width: 100%;
      min-height: 120px;
      background: #f4f4f4;
      margin-bottom: 20px;
      padding: 20px 40px;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      z-index: 0;
      @include media_query(M) {
        padding: 20px 26px;
      }
      &::after {
        content: "";
        position: absolute;
        height: 240px;
        background-image: url("../images/mb-right-leaf-bg.svg");
        bottom: -125px;
        width: 65px;
        z-index: -1;
        right: 0;
      }
    }
    .pick-survey-details-area {
      padding: 20px 40px;
      @include media_query(M) {
        padding: 20px 26px;
      }
      .details-wrapper {
        margin-bottom: 40px;
        .heading {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 8px;
        }
        .sub-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 8px;
        }
        .description {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 16px;
          li {
            margin-bottom: 8px;
          }
        }
        .redirection-btn {
          min-width: 230px;
          display: inline-block;
          @include media_query(M) {
            display: block;
          }
          // For tablet Portrait
          @media only screen and (min-width: 768px) and (orientation: portrait) {
            display: inline-block;
          }
          .btn-area {
            width: 100%;
            height: 42px;
            .app-btn {
              box-shadow: none;
              text-align: center;
            }
          }
        }
      }
    }
  }
  &.close-pick-survey {
    margin-left: -595px;
    @include media_query(M) {
      margin-left: -110%;
    }
    // For tablet Portrait
    @media only screen and (min-width: 768px) and (orientation: portrait) {
      margin-left: -595px;
    }
  }
}
