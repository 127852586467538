.custom-overlay {
  padding: 0;
  background: rgba(0, 0, 0, 0.66);
}
.modal-container {
  width: 675px;
  // border-radius: 7px;
  background-color: transparent;
  padding: 0;
  .close-icon {
    width: 10px;
    height: 10px;
    background-image: url("../images/white-close.svg");
    svg {
      path {
        display: none;
      }
    }
    &:focus {
      outline: 0;
    }
  }
  .popup-wrapper {
    .popup-close-icon {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background-color: $darkGreen;
    }
    .popup-header {
      margin-bottom: 20px;
      .popup-label {
        // font-family: "Temeraire";
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
      }
      .popup-description {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px;
      }
    }
    .popup-inputs {
      margin-bottom: 12px;
    }
    .popup-btn {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      right: 20px;
      bottom: 20px;
      .btn-area {
        width: 120px;
        height: 35px;
        .app-btn {
          text-align: center;
          box-shadow: none;
        }
      }
    }
  }
  &.email-send-modal,
  &.why-asking-modal,
  &.save-results-modal {
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // align-items: flex-end;
    // padding: 20px 18px;
    padding: 32px 16px;
    width: 343px;
    // height: 200px;
    background: $white;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
    border-radius: 0px;
    // position: absolute;
    margin: auto;
  }
  &.email-send-modal {
    min-height: 210px;
    max-height: 60%;
    @include media_query(M) {
      max-height: 90%;
    }
    .popup-wrapper {
      .popup-header {
        .popup-label {
          font-size: 16px;
        }
      }
    }
  }
  &.why-asking-modal {
    //width: 343px;
    max-height: 60%;
    padding: 0 16px;
    @include media_query(M) {
      max-height: 90%;
    }
    // //For Phone landscape
    @media only screen and (orientation: landscape) {
      @include media_query(L) {
        max-height: 90%;
      }
    }
    .popup-wrapper {
      .popup-header-container {
        height: 75px;
        padding: 32px 16px;
        position: sticky;
        top: 0;
        background: white;
        margin: 0 -16px;
        z-index: 1;
        .popup-header {
          margin-bottom: 0px;
          // border-bottom: 1px solid #d6d6d6;
          .popup-label {
            font-weight: bold;
            // padding-bottom: 20px;
          }
        }
      }
      .popup-inner-wrapper {
        // margin-bottom: 32px;
        .list-container {
          ul {
            .item {
              // font-family: "Montserrat";
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              // color: #6d6d6d;
              margin-bottom: 15px;
              position: relative;
              // padding-left: 20px;
              // &::before {
              //   content: "";
              //   width: 5px;
              //   height: 5px;
              //   background: $darkGreen;
              //   position: absolute;
              //   left: 0px;
              //   top: 8px;
              //   border-radius: 50%;
              // }
              @include media_query(M) {
                font-size: 14px;
                line-height: 18px;
              }
              &:last-child {
                margin-bottom: 0;
              }
              .title {
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                color: #2c2c2c;
              }
            }
          }
        }
      }
      .popup-bottom-section {
        width: 100%;
        height: 75px;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        bottom: 0;
        // margin: 0 -16px;
        .label {
          cursor: pointer;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: #006f44;
        }
      }
    }
  }
  &.save-results-modal {
    min-height: 210px;
    max-height: 60%;
    padding: 38px 32px;
    @include media_query(M) {
      max-height: 90%;
    }
    .popup-wrapper {
      .popup-header {
        .popup-label {
          font-weight: bold;
          margin-bottom: 8px;
        }
      }
      .popup-button-wrapper {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        .btn-wrapper {
          // margin-bottom: 15px;
          .btn-area {
            width: 209px;
            height: 48px;
            .app-btn {
              text-align: center;
              box-shadow: none;
            }
          }
        }
      }
      .popup-bottom-section {
        width: 100%;
        height: 25px;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        bottom: 0;
        // margin: 0 -16px;
        .label {
          cursor: pointer;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: #006f44;
        }
      }
    }
  }
  &.difference-check-modal {
    width: 80%;
    max-width: 80%;
    height: 600px;
    padding: 30px;
    background: $white;
  }
  //admin common popup
  &.admin-modal {
    width: 375px;
    min-height: 210px;
    padding: 30px 45px;
    background: $white;
    .popup-wrapper {
      .popup-header {
        margin-bottom: 8px;
        .popup-label {
          font-size: 24px;
          line-height: 28px;
        }
      }
      .popup-inner-wrapper {
        margin-bottom: 18px;
        font-size: 16px;
        line-height: 24px;
        .popup-detail {
        }
      }
    }
    .popup-button-wrapper {
      display: flex;
      // flex-direction: column;
      justify-content: space-around;
      align-items: center;
      .btn-wrapper {
        // margin-bottom: 15px;
        .btn-area {
          width: 120px;
          height: 48px;
          .app-btn {
            text-align: center;
            box-shadow: none;
          }
        }
      }
    }
  }

  //Save Test case popup
  &.save-test-case-modal {
    width: 385px;
    min-height: 350px;
    padding: 30px;
    background: $white;
    .popup-wrapper {
      .popup-header {
        margin-bottom: 20px;
        .popup-label {
          font-size: 24px;
          line-height: 28px;
        }
      }
      .popup-inner-wrapper {
        margin-bottom: 18px;
        font-size: 16px;
        line-height: 24px;
        .input-container {
          margin-bottom: 35px;
        }
        .tags-container {
          max-height: none;
          .title {
            font-size: 16px;
            line-height: 24px;
          }
          .tags-wrapper {
            width: auto;
            .tags {
              background: #e4e4e4;
              border-radius: 24px;
              border: none;
              padding: 3px 12px;
              height: 24px;
              min-width: 65px;
              text-align: center;
              .label {
                margin-right: 0px;
              }
            }
          }
        }
      }
    }
    .popup-button-wrapper {
      display: flex;
      // flex-direction: column;
      justify-content: space-around;
      align-items: center;
      .btn-wrapper {
        // margin-bottom: 15px;
        .btn-area {
          min-width: 120px;
          height: 48px;
          .app-btn {
            text-align: center;
            box-shadow: none;
          }
        }
      }
    }
  }
}
