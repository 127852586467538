.personal-info-container {
  // height: calc(100vh - 70px);
  // overflow-y: hidden;
  // position: relative;
  display: flex;
  // align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @include media_query(M) {
    //display: flex;
    // flex-direction: column;
    //justify-content: flex-end;
    display: flex;
    //justify-content: unset;
    align-items: flex-start;
  }
  //For Phone landscape
  @media only screen and (orientation: landscape) {
    @include media_query(L) {
      // justify-content: unset;
      // overflow-y: auto;
      // top: 85px;
    }
  }
  // For tablet Portrait
  @media only screen and (min-width: 768px) and (orientation: portrait) {
    align-items: unset;
    overflow-y: auto;
    justify-content: center;
  }
  .count-wrapper {
    width: 100%;
    padding: 10px 20px;
    .count {
      // font-family: "Temeraire";
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #6d6d6d;
    }
    @include media_query(M) {
      position: absolute;
      top: 0px;
    }
    //For Phone landscape
    @media only screen and (orientation: landscape) {
      @include media_query(L) {
        position: relative;
      }
    }
  }
  .inner-wrapper {
    position: relative;
    width: 100%;

    // padding: 0;
    // height: calc(100% - 80px);
    // justify-content: center;
    @include media_query(M) {
      padding-bottom: 30px;
      padding: 0px 11px;
      height: auto;
      justify-content: unset;
    }
    // For tablet Portrait
    @media only screen and (min-width: 768px) and (orientation: portrait) {
      // justify-content: center;
      height: 100%;
      padding: 0;
    }
    //For Phone landscape
    @media only screen and (orientation: landscape) {
      @include media_query(L) {
        height: auto;
        justify-content: unset;
        padding: 0;
      }
    }
    .question-wrappper {
      min-width: 100%;
      margin-bottom: 20px;
      align-items: center;
      text-align: center;
      flex-direction: column;
      background-color: #f4f4f4;
      min-height: 200px;
      display: flex;
      justify-content: flex-end;
      padding: 20px 25%;
      position: relative;
      z-index: -1;
      .question-label {
        // text-align: center;
        margin-bottom: 10px;
      }
      .question-sub-label {
        // font-family: "Montserrat";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #414141;
        @include media_query(M) {
          text-align: left;
          font-size: 14px;
        }
        //For Phone landscape
        @media only screen and (orientation: landscape) {
          @include media_query(L) {
            text-align: center;
          }
        }
        // For tablet Portrait
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          text-align: center;
        }
      }
      @include media_query(M) {
        // width: 100%;
        align-items: unset;
        text-align: left;
        padding: 20px 16px;
        margin-left: -11px;
        margin-right: -11px;
        // margin-bottom: 25px;
      }
      //For Phone landscape
      @media only screen and (orientation: landscape) {
        @include media_query(L) {
          // width: 70%;
          align-items: center;
          text-align: center;
          // padding: 20px 25%;
          margin-left: 0px;
          margin-right: 0px;
        }
      }
      // For tablet Portrait
      @media only screen and (min-width: 768px) and (orientation: portrait) {
        align-items: center;
        text-align: center;
        // padding: 20px 20%;
        margin-left: 0px;
        margin-right: 0px;
      }
      // For tablet Portrait
      // @media only screen and (min-width: 992px) and (orientation: landscape) {
      //   width: 40%;
      //   align-items: center;
      // }
      &.question-type-input {
        @include media_query(M) {
          margin-bottom: 20px;
        }
      }

      //background icon
      &::after,
      &::before {
        content: "";
        position: absolute;
        width: 160px;
        height: 240px;
        background-image: url("../images/background-leaf.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
      &::after {
        z-index: -1;
        right: 0;
        bottom: -45px;
        @include media_query(M) {
          background-image: url("../images/mb-right-leaf-bg.svg");
          bottom: -30px;
          width: 80px;
        }
      }
      &::before {
        z-index: -1;
        left: 0;
        top: 80%;
        transform: rotateZ(170deg) rotate(275deg) rotateY(180deg);
        @include media_query(M) {
          background-image: url("../images/mb-left-leaf-bg.svg");
          left: 0;
          transform: none;
          top: 120%;
        }
      }
    }
    .input-container {
      width: 350px;
      @include media_query(M) {
        width: 100%;
      }
      // For tablet Portrait
      @media only screen and (min-width: 768px) and (orientation: portrait) {
        width: 350px
      }
    }
    .we-asking-wrapper {
      cursor: pointer;
      margin-bottom: 50px;
      .inner-wrapper {
        flex-direction: row;
        display: flex;
        align-items: center;
        .label {
          // font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 17px;
          margin-left: 10px;
          color: $darkGreen;
        }
        @include media_query(M) {
          padding: 0;
        }
      }
    }
    .input-container {
      margin-bottom: 50px;
    }
    // For question type single word
    &.information-wrapper {
      .input-container {
        margin-bottom: 50px;
      }
    }
    // For question type height and weight
    &.height-weight-container {
      .question-wrappper {
        width: 80%;
        @include media_query(M) {
          width: 100%;
        }
      }
      .height-container {
        .height-title {
          margin-top: 50px;
          margin-bottom: 12px;
        }
        //dropdown input container
        //height input container
        .input-container {
          position: relative;
          @include media_query(S) {
            width: 100%;
          }
          .unit-class {
            display: inline;
            position: absolute;
            @include unit-class(28);
          }
          .blocks-wrapper {
            margin: 0px -10px;
            // margin-top: 12px;
            .blocks-row {
              display: flex;
              width: 100%;
              // margin: 0px -10px;
              .feet-inch-container {
                // margin-right: 10%;
                width: 50%;
                padding: 0px 10px;
                .input-wrapper {
                  .option-wrapper {
                    .unit-class {
                      position: absolute;
                      display: inline;
                      @include unit-class(10);
                    }
                  }
                }
              }
            }
            .error-info {
              margin: 0 10px;
              line-height: 22px;
              margin-top: 5px;
              position: absolute;
              color: #e3212b;
            }
          }
        }
      }
      //weight input container
      .input-container {
        position: relative;
        @include media_query(S) {
          width: 100%;
        }
        .unit-class {
          position: absolute;
          @include unit-class(20);
        }
      }
    }
    // For question type mutiple selection with short answer and mutiple selection
    &.short-answer-container,
    &.mutiple-selection-container {
      .question-wrappper {
        // width: 100%;
        .question-label {
          @include media_query(M) {
            font-size: 21px;
          }
        }
        //For Phone landscape
        @media only screen and (orientation: landscape) {
          @include media_query(L) {
            width: 63%;
          }
        }
      }
      .short-answer-wrapper,
      .multiple-answer-wrapper {
        // margin-bottom: 50px;
        .answer-list-conatiner {
          .checkbox-container {
            margin-bottom: 10px;
            .checkbox-wrapper {
              cursor: pointer;
              width: 345px;
              margin-bottom: 7px;
              min-height: 48px;
              border: 1px solid #cac0ac;
              background-color: #fff;
              box-sizing: border-box;
              box-shadow: 0px 0px 5px rgb(0 0 0 / 8%);
              border-radius: 10px;
              display: flex;
              align-items: center;
              padding: 8px 16px;
              // display: flex;
              @include media_query(M) {
                width: 100%;
              }
              // For tablet Portrait
              @media only screen and (min-width: 768px) and (orientation: portrait) {
                width: 345px
              }
              .list-label {
                // font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                padding-left: 8px;
                &.active {
                  font-weight: 700;
                }
              }
              &:last-child {
                margin-bottom: 0px;
              }
              &.active {
                border: 1px solid #66a98f;
              }
              &:hover {
                background-color: #f4f4f4;
              }
              &.checkbox-error {
                border: 1px solid #d91734;
              }
              &.disable {
                pointer-events: none;
                background-color: #f4f4f4;
                border: none;
                box-shadow: none;
                .checkbox {
                  .checkmark {
                    // border: 2px solid #707070;
                  }
                }
                .list-label {
                  color: #707070;
                }
              }
            }
            &.checkbox-grid-layout {
              display: grid;
              grid-template-columns: 250px 250px;
              grid-row: auto;
              grid-column-gap: 10px;
              grid-row-gap: 10px;
              @include media_query(M) {
                grid-template-columns: 100%;
              }
              // For tablet Portrait
              @media only screen and (min-width: 768px) and (orientation: portrait) {
                grid-template-columns: 250px 250px;
              }
            }
          }
        }
        .error-msg {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: #e3212b;
          margin-top: 5px;
          text-align: center;
          @include media_query(M) {
          text-align: left;
          }
        }
      }
    }
    // For question type mutiple selection with short answer
    &.short-answer-container {
      .short-answer-wrapper {
        margin-bottom: 30px !important;
        .answer-list-conatiner {
          .checkbox-container {
            .checkbox-wrapper {
              // display: block;
              padding: 12px 16px;
              .list-label {
                // padding-left: 30px;
              }
              .input-container {
                width: 100%;
                margin-bottom: 0;
                margin-top: 15px;
              }
              &.with-input {
                display: block;
                .list-label {
                  padding-left: 30px;
                }
              }
            }
          }
        }
      }
      //For Multiple dropdown
      .multiple-dropdown-wrapper {
        .dropdown-wrapper {
          .dropdown-container {
            .dropdown-options {
              padding: 0;
              .dropdown-value {
                padding: 2px 12px;
                &:hover,
                &.active {
                  background-color: #f4f4f4;
                }
              }
            }
          }
        }
      }
    }
    // For question type mutiple selection
    &.mutiple-selection-container {
      .multiple-answer-wrapper {
        margin-bottom: 40px !important;
        .answer-list-conatiner {
          .checkbox-wrapper {
            margin-bottom: 0px !important;
            width: auto !important;
            position: relative;
            .custom-icon {
              width: 20px;
              height: 20px;
              position: absolute;
              right: 16px;
              background-image: url("../images/moon.svg");
            }
            &.mandatory {
              pointer-events: none;
              background-color: #f4f4f4;
              border: 1px solid #66a98f;
            }
          }
          .error-msg {
            text-align: center;
            @include media_query(M) {
            text-align: left;
            }
          }
        }
        &.short-raking-answer {
          .answer-list-conatiner{
            .options-wrapper{
              margin-bottom: 0;
              .options-groups{
                display: grid;
                grid-template-columns: 250px 250px;
                grid-row: auto;
                grid-column-gap: 10px;
                grid-row-gap: 10px;
                @include media_query(M) {
                  grid-template-columns: 100%
                }
                // For tablet Portrait
                @media only screen and (min-width: 768px) and (orientation: portrait) {
                  grid-template-columns: 250px 250px;
                } 
                .option{
                  width: auto;
                  margin-bottom: 0px;
                  @include media_query(M) {
                    margin-bottom: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
    //For question type search and selection
    &.search-select-container {
      .search-select-wrapper {
        position: relative;
        margin-bottom: 30px;
        .checkbox-wrapper {
          margin-top: 30px !important;
          // display: flex;
          .list-label {
            // font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            padding-left: 28px;
            &.active {
              font-weight: 700;
            }
          }
          &.disable {
            pointer-events: none;
            .checkbox {
              .checkmark {
                border: 2px solid #707070;
              }
            }
            .list-label {
              color: #707070;
            }
          }
        }
        .error-msg {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: #e3212b;
          margin-top: 5px;
          text-align: left;
          // @include media_query(M) {
          // text-align: left;
          // }
        }
      }
    }
  }
  &.top-align {
    .inner-wrapper {
      .question-wrappper {
        min-height: auto;
      }
    }
  }
}
