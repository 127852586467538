.greeting-container {
  @include media_query(M) {
    display: flex;
    align-items: center;
    height: calc(100vh - 150px);
  }
  .inner-wrapper {
    justify-content: center;
    height: 100%;
    .bg-image-wrapper {
      width: 270px;
      height: 400px;
      // margin-bottom: 50px;
      position: relative;

      .image {
        width: 100%;
        height: 100%;
        background-image: url("../../assets/leaf.png");
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        @include media_query(M) {
          position: absolute;
          margin: auto;
          left: 0;
          right: 0;
          width: 264px;
        }
        //For Phone landscape
        @media only screen and (orientation: landscape) {
          @include media_query(L) {
            position: relative;
            width: 100%;
          }
        }
        // For tablet Portrait
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          position: relative;
          width: 100%;
        }
      }

      //For Phone landscape
      @media only screen and (orientation: landscape) {
        @include media_query(L) {
          // width: 370px;
          // height: 260px;
          // margin-bottom: 50px;
        }
      }
      // For tablet Portrait
      @media only screen and (min-width: 768px) and (orientation: portrait) {
        width: 270px;
        // height: 260px;
        // margin-bottom: 100px;
      }
    }
    // .image-wrapper {
    //   height: 270px;
    //   width: 270px;
    //   .image {
    //     background-image: url("../../assets/greeting-animation.png");
    //   }
    //   @include media_query(M) {
    //     width: 100%;
    //     margin-bottom: 50px;
    //   }
    //   //For Phone landscape
    //   @media only screen and (orientation: landscape) {
    //     @include media_query(L) {
    //       width: 270px;
    //     }
    //   }
    //   // For tablet Portrait
    //   @media only screen and (min-width: 768px) and (orientation: portrait) {
    //     width: 270px;
    //   }
    // }
    .name-input-wrapper {
      .question-label {
        margin-bottom: 0px;
        text-align: center;
      }
      // @include media_query(M) {
      //   text-align: center;
      // }
    }
    //For Phone landscape
    @media only screen and (orientation: landscape) {
      @include media_query(L) {
        height: auto;
      }
    }
  }
}
