.testCase-dashboard {
  padding: 30px 45px;
  padding-top: 100px;
  height: 100vh;
  .create-testCase-wrapper {
    position: relative;
    margin-bottom: 30px;
    .back-area {
      position: absolute;
      cursor: pointer;
      .back-btn {
        width: 14px;
        height: 24px;
        background-image: url("../images/green-arrow.svg");
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        cursor: pointer;
        transform: rotate(180deg);
      }
      .label {
        margin-left: 25px;
        font-size: 16px;
        line-height: 24px;
        color: #006f44;
        font-weight: 600;
      }
    }
    .heading {
      text-align: center;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
    }
    .edit-area {
      width: 180px;
      height: 55px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .btn-area {
        width: 94px;
        height: 36px;
        .app-btn {
          text-align: center;
        }
      }
      .delete-btn {
        cursor: pointer;
        font-size: 16px;
        line-height: 24px;
        color: #006f44;
        border-bottom: 2px solid #006f44;
      }
    }
  }
  .middle-area {
    // height: 100%;
    .case-wrapper {
      //   height: 100%;
      position: relative;
      // .view-rules-btn {
      //   // background-color: yellow;
      //   width: 35px;
      //   height: 35px;
      //   // border-radius: 50%;
      //   z-index: 1;
      //   position: absolute;
      //   top: 70px;
      //   left: 0;
      //   right: 0;
      //   margin: auto;
      //   cursor: pointer;
      //   // width: 135px;
      //   // height: 35px;
      //   background: #ffffff;
      //   border: 2px solid #2c2c2c;
      //   box-sizing: border-box;
      //   border-radius: 18px;
      //   text-align: center;
      //   line-height: 35px;
      //   white-space: nowrap;
      //   span {
      //     display: none;
      //   }
      //   &::before,
      //   &::after {
      //     content: "";
      //     width: 9px;
      //     height: 12px;
      //     background-size: contain;
      //     background-repeat: no-repeat;
      //     z-index: 2;
      //     position: absolute;
      //     top: 0;
      //     bottom: 0;
      //     margin: auto;
      //   }
      //   &::before {
      //     background-image: url("../images/left-arrow.svg");
      //     left: 6px;
      //   }
      //   &::after {
      //     background-image: url("../images/right-arrow.svg");
      //     right: 6px;
      //   }
      //   &:hover {
      //     width: 135px;
      //     height: 35px;
      //     span {
      //       display: block;
      //     }
      //     &::before {
      //       left: 12px;
      //     }
      //     &::after {
      //       right: 12px;
      //     }
      //   }
      // }
      // .bootstrap-wrapper {
      //   // height: 100%;
      //   /* width: 100%; */
      //   display: flex;
      //   justify-content: center;
      //   .row {
      //   height: 100%;
      .test-case-table {
        table-layout: fixed;
        // Common Class for all section
        .section {
          // height: 580px;
          height: calc(100vh - 200px);
          width: 100%;
          padding-right: 2px;
          // min-width: 400px;
          .title {
            text-align: center;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 10px;
          }
          .inner-wrapper {
            padding: 10px;
            position: relative;
            .title-header {
              height: 100px;
              border-bottom: 1px solid #e4e4e4;
              margin: 0 -10px;
              display: flex;
              flex-direction: column;
              align-items: center;
              // justify-content: center;
              .title {
                font-weight: 600;
                font-size: 24px;
                line-height: 30px;
                // border-bottom: 1px solid #e4e4e4;
                width: 100%;
                /* box-sizing: revert; */
                padding-bottom: 10px;
                // text-align: center;
              }
            }
            .main-input {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              position: relative;
              .search-wrapper {
                width: 345px;
                height: 35px;
                position: relative;
                .input-wrapper {
                  .custom-input-wrapper {
                    .custom-input {
                      // border: 2px solid #2c2c2c;
                      box-sizing: border-box;
                      border-radius: 10px;
                      font-size: 16px;
                    }
                  }
                }
                .search-icon,
                .cross-icon {
                  width: 15px;
                  height: 15px;
                  position: absolute;
                  top: 0;
                  right: 10px;
                  bottom: 0;
                  margin: auto;
                }
              }
            }
            .section-options-wrapper {
              width: 35px;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              right: 10px;
              .three-dot-icon {
                width: 4px;
                height: 18px;
                background-image: url("../images/three-dots.svg");
              }
              .indicator {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: transparent;
                position: absolute;
                top: 0;
                right: 0;
                &.orange {
                  background: #f1c400;
                }
                &.green {
                  background: #7fbc96;
                }
              }
              .options-view {
                position: absolute;
                width: 200px;
                min-height: 42.5px;
                background-color: #fff;
                border: 1px solid #000000;
                box-sizing: border-box;
                border-radius: 10px;
                padding: 16px;
                top: 45px;
                right: 10px;
                z-index: 11;
                .option {
                  font-size: 14px;
                  line-height: 18px;
                  margin-bottom: 10px;
                  padding-bottom: 10px;
                  border-bottom: 1px solid #e4e4e4;
                  font-weight: 600;
                  cursor: pointer;
                  position: relative;
                  &:last-child {
                    margin-bottom: 0;
                    border: none;
                    padding: 0;
                  }
                  .indicator {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: transparent;
                    position: absolute;
                    top: 4px;
                    right: 0;
                    &.orange {
                      background: #f1c400;
                    }
                    &.green {
                      background: #7fbc96;
                    }
                  }
                }
              }
            }
          }
        }
        .columnResizer {
          width: 2px;
          background-color: #fff;
        }

        //Input Section CSS
        .input-area {
          // background-color: red;
          position: relative;
          .view-rules-btn {
            // background-color: yellow;
            width: 35px;
            height: 35px;
            // border-radius: 50%;
            z-index: 1;
            position: absolute;
            top: 20px;
            left: auto;
            right: -16px;
            margin: auto;
            cursor: pointer;
            // width: 135px;
            // height: 35px;
            background: #ffffff;
            border: 2px solid #2c2c2c;
            box-sizing: border-box;
            border-radius: 18px;
            text-align: center;
            line-height: 35px;
            white-space: nowrap;
            span {
              display: none;
            }
            &::before,
            &::after {
              content: "";
              width: 9px;
              height: 12px;
              background-size: contain;
              background-repeat: no-repeat;
              z-index: 2;
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
            }
            &::before {
              background-image: url("../images/left-arrow.svg");
              left: 6px;
            }
            &::after {
              background-image: url("../images/right-arrow.svg");
              right: 6px;
            }
            &:hover {
              width: 135px;
              height: 35px;
              right: -70px;
              span {
                display: block;
              }
              &::before {
                left: 12px;
              }
              &::after {
                right: 12px;
              }
            }
          }
          .inner-wrapper {
            border: 1px solid #e4e4e4;
            border-radius: 15px 0 0 15px;
            height: 100%;
            .results-search-info {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 40px;
              color: #707070;
              padding: 0 10px;
            }
            // .title-header {
            //   .main-input {
            //     width: 400px;
            //     height: 35px;
            //     position: relative;
            //     .input-wrapper {
            //       .custom-input-wrapper {
            //         .custom-input {
            //           // border: 2px solid #2c2c2c;
            //           box-sizing: border-box;
            //           border-radius: 10px;
            //         }
            //       }
            //     }
            //     .search-icon {
            //       width: 15px;
            //       height: 15px;
            //       position: absolute;
            //       top: 0;
            //       right: 10px;
            //       bottom: 0;
            //       margin: auto;
            //     }
            //   }
            // }
          }
        }
        //Rules Section CSS
        .rules-area {
          // background-color: green;
          // border: 1px solid #e4e4e4;
          .inner-wrapper {
            border: 1px solid #e4e4e4;
            height: 100%;
            .no-rules-wrapper {
              height: calc(100% - 90px);
              background: #f4f4f4;
              // width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 -10px;
              .no-rules-msg {
                font-size: 16px;
              }
            }
            .toggle-area {
              // width: 100%;
              height: 65px;
              display: flex;
              align-items: center;
              border-bottom: 1px solid #e4e4e4;
              padding: 0 50px;
              margin: 0 -10px;
              .switch {
                height: 35px;

                .toggle-container {
                  width: 100%;
                  height: 100%;
                  border: none !important;
                  box-sizing: border-box;
                  border-radius: 20px;
                  padding: 0;
                  background-color: #e4e4e4 !important;
                  &::before {
                    background-color: #fff;
                    border: 2px solid #006f44;
                    box-sizing: border-box;
                    border-radius: 20px;
                    width: 50%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    bottom: 0;
                  }
                  .left-box-container,
                  .right-box-container {
                    height: 100%;
                    padding: 0;
                    color: #2c2c2c;
                    text-align: center;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                  .left-box-container {
                    color: #006f44;
                  }
                }
                input:checked + .slider {
                  .right-box-container {
                    color: #006f44;
                  }
                  .left-box-container {
                    color: #000;
                  }
                }
              }
            }
            .rules-search-info {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 40px;
              color: #707070;
            }
            .rules-inner-wrapper {
              padding: 8px 20px;
              height: calc(100% - 155px);
              overflow: auto;
              margin: 0 -10px;
              .aggregated-area {
                .aggregated-list {
                  display: flex;
                  width: 100%;
                  .attribute,
                  .score {
                    width: 50%;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 40px;
                  }
                  .attribute {
                    border-right: 1px solid #e4e4e4;
                  }
                  .score {
                    padding-left: 12px;
                  }
                }
              }
              .custom-accordion {
                margin: -10px -20px;
                height: auto;
                // position: relative;
                // overflow: auto;
                .accordion {
                  .accordion__item {
                    .accordion_rules_button {
                      .item-btn-wrapper {
                        .question-title {
                          width: auto;
                        }
                        .handleArrow {
                          @include arrow;
                          position: absolute;
                          bottom: 19px;
                          -webkit-transform: rotate(45deg);
                        }
                      }
                      &[aria-expanded="true"] {
                        .handleArrow {
                          @include arrow;
                          bottom: 12px;
                          -webkit-transform: rotate(-135deg);
                        }
                      }
                    }
                    .aggregated-area {
                      padding: 10px 20px;
                      .action-name {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                      }
                      .aggregated-list {
                        .attribute,
                        .score {
                          font-weight: normal;
                          font-size: 16px;
                          line-height: 28px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        //Results Section CSS
        .results-area {
          // background-color: blue;
          .inner-wrapper {
            border: 1px solid #e4e4e4;
            border-radius: 0 15px 15px 0px;
            height: 100%;
            .title-header {
              .title {
                display: flex;
                justify-content: space-evenly;
                .tab {
                  cursor: pointer;
                  &.active {
                    border-bottom: 5px solid #006f44;
                    margin-bottom: -10px;
                    color: #006f44;
                  }
                }
              }
            }
            .filter-area {
              // width: 100%;
              height: 65px;
              border-bottom: 1px solid #e4e4e4;
              margin: 0 -10px;
              .dropdowns-wrapper {
                display: flex;
                align-items: center;
                padding: 0 30px;
                height: 100%;
                width: 100%;
                .filter-dropdown-container {
                  position: relative;
                  width: 100%;
                  margin-right: 20px;
                  &:last-child {
                    margin: 0;
                  }
                  .dropdown-input {
                    position: relative;
                    .custom-input {
                      padding-right: 30px;
                    }
                  }
                  .arrowDown {
                    bottom: 17px;
                  }
                  .dropdown-list-area {
                    width: 100%;
                    border: 2px solid #2c2c2c;
                    box-sizing: border-box;
                    border-radius: 10px;
                    position: absolute;
                    top: 45px;
                    padding: 16px;
                    max-height: 180px;
                    overflow: auto;
                    z-index: 1;
                    background: #fff;
                    .list-view {
                      .list {
                        // font-weight: 600;
                        font-size: 14px;
                        line-height: 30px;
                        margin-bottom: 10px;
                        cursor: pointer;
                        &.active {
                          font-weight: 600;
                        }
                        &:last-child {
                          margin-bottom: 0px;
                        }
                      }
                    }
                  }
                }
                .main-input {
                  position: relative;
                  width: 100%;
                  .search-icon {
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    top: 0;
                    right: 10px;
                    bottom: 0;
                    margin: auto;
                  }
                }
              }
            }

            .custom-accordion {
              height: calc(100% - 215px);
              &.for-no-results {
                height: calc(100% - 150px);
              }
              .results-search-info {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 40px;
                color: #707070;
              }
              // position: relative;
              // overflow: auto;
              .accordion {
                .accordion__item {
                  .accordion_results_button {
                    .item-btn-wrapper {
                      .question-title {
                        width: auto;
                      }
                      .handleArrow {
                        @include arrow;
                        position: absolute;
                        bottom: 19px;
                        -webkit-transform: rotate(45deg);
                      }
                    }
                    &[aria-expanded="true"] {
                      .handleArrow {
                        @include arrow;
                        bottom: 12px;
                        -webkit-transform: rotate(-135deg);
                      }
                    }
                  }
                  .results-details-wrapper {
                    .product-details {
                      padding: 10px 20px;
                      .prod-data {
                        margin-bottom: 10px;
                        font-size: 16px;
                        line-height: 24px;
                      }
                    }
                  }
                }
              }
              .results-list-view {
                padding: 15px 20px;

                .list {
                  font-size: 16px;
                  line-height: 30px;
                  margin-bottom: 10px;
                }
                &.for-foodtips {
                  padding: 0px 45px;
                }
              }
              .results-list-view-subheading {
                padding: 15px 45px;
                font-weight: 600;
              }
              .no-results-wrapper {
                height: 100%;
                width: 100%;
                background: #f4f4f4;
                display: flex;
                align-items: center;
                justify-content: center;
                .no-results-msg {
                  font-size: 16px;
                  line-height: 24px;
                }
              }
            }
          }
        }
      }
      // }
    }
  }
}
