@mixin clearfix {
  &:after {
    content: "";
    clear: both;
    display: table;
    visibility: hidden;
    display: block;
    height: 0;
  }
}
@mixin border-radius($radius...) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -o-box-shadow: $shadow;
  box-shadow: $shadow;
}
@mixin inline-block {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

// Aligns Code vertically middle
@mixin align-middle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
@mixin display-table {
  display: table;
}
@mixin display-table-cell {
  display: table-cell;
  vertical-align: middle;
}

@mixin box-sizing($sizing) {
  -webkit-box-sizing: $sizing;
  -moz-box-sizing: $sizing;
  box-sizing: $sizing;
}

@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  transform: rotate($deg);
}

@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  transform: $transform;
}

@mixin calc($property, $expression) {
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: -moz-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin arrow {
  border: solid $darkBlack;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(45deg);
  position: absolute;
  justify-content: left;
  right: 14px;
}

@mixin unit-class($value) {
  right: 0px;
  // top: 10px;
  bottom: 12px;
  left: 20px + $value;
  // font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
}
