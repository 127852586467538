.btn-area {
  // width: 120px;
  // height: 50px;
  width: 140px;
  height: 50px;
  // @include media_query(M) {
  //   // width: 120px;
  //   // height: 45px;
  //   width: 140px;
  //   height: 60px;
  // }
  // For Phone landscape
  // @media only screen and (orientation: landscape) {
  //   @include media_query(L) {
  //     // width: 120px;
  //     // height: 45px;
  //     width: 140px;
  //     height: 60px;
  //   }
  // }
  .app-btn {
    width: 100%;
    height: 100%;
    background: $darkGreen;
    // box-shadow: 0px 0px 16px rgb(0 0 0 / 25%);
    border-radius: 32px;
    color: $white;
    border: none;
    position: relative;
    cursor: pointer;
    // font-family: "Montserrat";
    text-align: left;
    padding: 0 25px;
    font-weight: 700;
    font-size: 16px;
    &.btn-icon {
      &::after {
        content: "";
        background-image: url("../../images/next-arrow.svg");
        position: absolute;
        top: 0;
        bottom: 0;
        right: 15px;
        height: 18px;
        width: 17px;
        background-repeat: no-repeat;
        background-size: contain;
        margin: auto;
      }
    }
    &.border-btn {
      border: 2px solid #006f44;
      background-color: white;
      color: #006f44;
    }
    // @include media_query(M) {
    &.disagree {
      background: #707070;
      box-shadow: none;
      pointer-events: none;
      color: #ffffff;
      // &.btn-icon {
      //   &::after {
      //     content: "";
      //     background-image: url("../../images/gray-arrow.svg");
      //   }
      // }
    }
    &.out-of-stock {
      background: #707070;
      box-shadow: none;
      color: #ffffff;
    }
    // }
    // For Phone landscape
    @media only screen and (orientation: landscape) {
      @include media_query(L) {
        &.disagree {
          background: #707070;
          color: #ffffff;
          box-shadow: none;
          pointer-events: none;
          // &.btn-icon {
          //   &::after {
          //     content: "";
          //     background-image: url("../../images/gray-arrow.svg");
          //   }
          // }
        }
      }
    }
    &.admin-btn {
      text-align: center;
      font-size: 16px;
      white-space: nowrap;
      line-height: 24px;
      // background: #2c2c2c;
    }
  }
  &.continue-btn {
    .app-btn {
      padding: 0 22px;
      &.btn-icon {
        &::after {
          right: 12px;
          height: 20px;
        }
      }
    }
  }
  &.quantity-btn-area {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 8px;
    .decrease-btn,
    .increase-btn {
      position: absolute;
      color: #006f44;
      font-weight: 600;
      cursor: pointer;
      z-index: 2;
    }
    .decrease-btn {
      left: 25px;
      font-size: 30px;
    }
    .increase-btn {
      right: 25px;
      font-size: 25px;
    }
    .app-btn {
      &.btn-quantity {
        background: transparent;
        border: 2px solid #006f44;
        box-sizing: border-box;
        box-shadow: none;
        color: #006f44;
        text-align: center;
      }
    }
  }
}
