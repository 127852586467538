.welcome-screen-container {
  .header-container {
    position: relative;
    .inner-wrapper {
      position: fixed;
      width: 100%;
      z-index: 1;
      background-color: #ffffff;
      .header-links-wrapper {
        display: none;
      }
      .hamburger-container {
        .hamburger-wrapper {
          .visible-myoption {
            display: none;
          }
        }
      }
    }
  }
  .middle-area {
    // height: 100vh;
    padding-top: 70px;
    position: relative;
    height: calc(100vh - 72px);
    //For Phone landscape
    @media only screen and (orientation: landscape) {
      @include media_query(L) {
        height: auto;
      }
    }
    .welcome-screen-wrapper {
      width: 100%;
      height: calc(100vh - 142px);
      position: relative;
      background-image: radial-gradient(
        100% 94.61% at 0% 7%,
        #ffffff 40.2%,
        rgba(255, 255, 255, 0) 67.73%
      );
      //url("../../assets/welcome-bg.png");
      // background-size: 100% 100%;
      background-size: cover;
      background-position: center;
      padding: 35px 45px;
      @include media_query(M) {
        background-image: radial-gradient(
          106.17% 106% at 0% -6%,
          #ffffff 35.36%,
          rgba(255, 255, 255, 0) 63.86%
        );
        //url("../../assets/mb-welcome-bg.png");
        height: calc(100vh - 300px);
        padding: 16px;
      }
      // For tablet Portrait
      @media only screen and (min-width: 768px) and (orientation: portrait) {
        height: calc(100vh - 182px);
        padding: 35px 45px;
      }
      //For Phone landscape
      @media only screen and (orientation: landscape) {
        @include media_query(L) {
          height: auto;
        }
      }
      .title-wrapper {
        margin-bottom: 35px;
        width: 280px;
        @include media_query(M) {
          width: 170px;
        }
        // For tablet Portrait
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          width: 280px;
        }
        .heading {
          font-weight: 600;
          font-size: 40px;
          line-height: 48px;
          color: #66a98f;
          margin-bottom: 30px;
          @include media_query(M) {
            font-size: 24px;
            line-height: 28px;
          }
          // For tablet Portrait
          @media only screen and (min-width: 768px) and (orientation: portrait) {
            font-size: 49px;
            line-height: 56px;
          }
        }
        .sub-title {
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          width: 325px;
          @include media_query(M) {
            font-size: 16px;
            line-height: 24px;
            width: 170px;
          }
          // For tablet Portrait
          @media only screen and (min-width: 768px) and (orientation: portrait) {
            font-size: 24px;
            line-height: 30px;
            width: 325px;
          }
        }
      }
      .learn-more-wrapper {
        width: 220px;
        @include media_query(M) {
          position: absolute;
          bottom: -70px;
          left: 0;
          right: 0;
          margin: auto;
          padding: 0 16px;
          width: 100%;
        }
        // For tablet Portrait
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          position: relative;
          padding: 0;
          margin: 0;
          bottom: 0;
          width: 220px;
        }
        .take-survey-btn {
          margin-bottom: 28px;
          margin-left: 0;
          .btn-area {
            .app-btn {
              @include media_query(M) {
                // box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
              }
              // For tablet Portrait
              @media only screen and (min-width: 768px) and (orientation: portrait) {
                box-shadow: none;
              }
            }
          }
        }
        .want-to-learn {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          @include media_query(M) {
            text-align: center;
          }
          .link {
            cursor: pointer;
            color: #006f44;
            text-decoration: underline;
            font-weight: 700;
          }
        }
      }
    }
  }
  .footer-area {
    height: 72px;
    .instruction {
      display: none;
    }
  }
}
