.test-filter-wrapper {
  height: 100%;
  position: relative;
  position: fixed;
  background: #ffffff;
  box-shadow: 4px 0px 5px rgba(0, 0, 0, 0.15);
  z-index: 111;
  width: 480px;
  right: 0;
  top: 0;
  padding: 0 48px;
  @include media_query(M) {
    width: 100%;
  }
  // For tablet Portrait
  @media only screen and (min-width: 768px) and (orientation: portrait) {
    width: 480px;
  }
  .filter-header-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 25px 00px;
    background: $white;
    border-bottom: 1px solid #e4e4e4;
    width: 100%;
    z-index: 1;
    .header-icon {
      width: 40px;
      height: 40px;
      background-color: #006f44;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 0;
      top: 0;
      position: absolute;
      .cross-icon {
        width: 15px;
        height: 15px;
        background-image: url("../images/white-close.svg");
      }
    }
    .header-title-area {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: end;
      margin-top: 12px;
      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
      }
      .clear-filter {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: #006f44;
        cursor: pointer;
        border-bottom: 2px solid #006f44;
      }
    }
  }
  .middle-wrapper {
    height: calc(100% - 200px);
    overflow-y: auto;
    .custom-accordion {
      height: 100%;
      margin: 0;
      .accordion {
        .accordion__item {
          .accordion_results_button {
            background: #fff;
            border-bottom: 1px solid #e4e4e4;
            .item-btn-wrapper {
              border: none;
              .question-title {
                width: auto;
              }
              .handleArrow {
                @include arrow;
                position: absolute;
                bottom: 19px;
                -webkit-transform: rotate(45deg);
              }
            }
            &[aria-expanded="true"] {
              border: none;
              .handleArrow {
                @include arrow;
                bottom: 12px;
                -webkit-transform: rotate(-135deg);
              }
            }
            &[aria-expanded="true"] ~ .accordion_results_panel {
              border-bottom: 1px solid #e4e4e4;
            }
          }
          .accordion_results_panel {
            padding: 0 20px;
            .options-area {
              margin-bottom: 16px;
              .option {
                margin-left: 28px;
              }
            }
          }
        }
      }
    }
  }

  .apply-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.08);
    margin: 0 -48px;
    .btn-area {
      width: 150px;
      height: 40px;
      .app-btn {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
      }
    }
  }

  &.close-pick-survey {
    margin-right: -485px;
    // @include media_query(M) {
    //   margin-left: -110%;
    // }
    // // For tablet Portrait
    // @media only screen and (min-width: 768px) and (orientation: portrait) {
    //   margin-left: -485px;
    // }
  }
}
