.terms-condition-wrapper {
  height: 100vh;
  position: relative;
  position: fixed;
  background: #ffffff;
  box-shadow: 4px 0px 5px rgba(0, 0, 0, 0.15);
  // height: 100vh;
  z-index: 111;
  width: 590px;
  @include media_query(M) {
    width: 100%;
    height: 100%;
  }
  .terms-condition-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 25px 28px;
    background: $white;
    box-shadow: 0 0 4px 2px rgba(0, 92, 80, 0.1);
    // position: fixed;
    width: 100%;
    z-index: 1;
    .header-title {
      // font-family: "Temeraire";
      font-style: normal;
      font-size: 24px;
      line-height: 22px;
      font-weight: 600;
      padding-left: 10px;
      // color: $darkGreen;
      @include media_query(M) {
        font-size: 18px;
      }
    }
    .header-icons {
      width: 80px;
      display: flex;
      justify-content: space-between;
      @include media_query(M) {
        width: 80px;
      }
      .icon-wrapper {
        position: relative;
        .header-options {
          position: absolute;
          top: 47px;
          right: 0;
          height: 50px;
          width: 100px;
          margin: auto;
          background: #ffffff;
          box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.15);
          border-radius: 0px 0px 10px 10px;
          display: flex;
          align-items: center;
          padding: 15px;
          cursor: pointer;
          z-index: 111;
          .label {
            // font-family: "Montserrat";
            // font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            // color: #6d6d6d;
            text-align: center;
          }
          &::before {
            content: "";
            width: 5px;
            height: 5px;
            background-color: antiquewhite;
            position: absolute;
            right: 7px;
            top: -20px;
            background: $darkGreen;
            border-radius: 50%;
          }
        }
      }
    }
    @include media_query(M) {
      padding: 16px;
    }
  }
  .terms-wrapper {
    padding: 25px 40px;
    // position: absolute;
    // top: 70px;
    overflow-y: auto;
    height: calc(100vh - 150px);
    // @include media_query(L) {
    //   &.blur-effect {
    //     &::after {
    //       content: "";
    //       width: 100%;
    //       height: 25px;
    //       bottom: 100px;
    //       left: 0;
    //       position: fixed;
    //       background: linear-gradient(
    //         360deg,
    //         #ffffff 0%,
    //         rgba(255, 255, 255, 0) 100%
    //       );
    //     }
    //     //For Phone landscape
    //     @media only screen and (orientation: landscape) {
    //       &::after {
    //         bottom: 80px;
    //       }
    //     }
    //   }
    // }
    @include media_query(M) {
      padding: 14px 16px;
      overflow-y: auto;
      height: calc(100% - 150px);
    }
    //For Phone landscape
    @media only screen and (orientation: landscape) {
      @include media_query(L) {
        overflow-y: auto;
        height: calc(100% - 150px);
      }
    }
    @media only screen and (min-width: 768px) and (orientation: portrait) {
      overflow-y: auto;
      height: calc(100% - 150px);
      padding: 25px 40px;
    }
    .inner-wrapper {
      .left-wrapper {
        // padding-right: 60px;
        margin-left: -5px;
        @include media_query(M) {
          padding: 0px;
          margin-left: 0px;
        }
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          max-width: 100%;
          max-width: 100%;
          padding: 0;
          padding-bottom: 30px;
        }
        //For Phone landscape
        @media only screen and (orientation: landscape) {
          @include media_query(L) {
            max-width: 100%;
          }
        }
        .main-title {
          // font-family: "gt-walsheim";
          font-size: 32px;
          line-height: 36px;
          // color: $darkGreen;
          margin-bottom: 30px;
          font-weight: bold;
          @include media_query(M) {
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 20px;
          }
          @media only screen and (min-width: 768px) and (orientation: portrait) {
            padding-bottom: 40px;
          }
        }
        .list-container {
          background: #f4f4f4;
          padding: 20px 40px;
          margin: 0 -40px;
          // margin-bottom: 30px;
          @include media_query(M) {
            margin-bottom: 30px;
            margin: 0 -15px;
          }
          ul {
            .terms {
              // font-family: "Montserrat";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              // color: $darkGreen;
              margin-bottom: 30px;
              position: relative;
              padding-left: 20px;
              @include media_query(M) {
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 15px;
              }
              @media only screen and (min-width: 768px) and (orientation: portrait) {
                font-size: 22px;
                line-height: unset;
              }
              &::before {
                content: "";
                width: 5px;
                height: 5px;
                background: $darkBlack;
                position: absolute;
                left: 0px;
                top: 8px;
                border-radius: 50%;
                @include media_query(M) {
                  width: 5px;
                  height: 5px;
                }
              }
              &:last-child {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
      .right-wrapper {
        // height: 100%;
        // display: flex;
        // align-items: center;
        // padding-top: 65px;
        @include media_query(M) {
          height: auto;
          padding: 0;
        }
        // For tablet Portrait
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          max-width: 100%;
          height: auto;
          padding: 0;
        }
        //For Phone landscape
        @media only screen and (orientation: landscape) {
          @include media_query(L) {
            max-width: 100%;
            align-items: unset;
            padding: 0;
            height: auto;
          }
        }
        .list-container {
          // padding: 0 20px;
          // height: 60vh;
          // overflow-y: auto;
          @include media_query(M) {
            padding: 0px;
            padding-bottom: 10px;
            height: auto;
          }
          //For Phone landscape
          @media only screen and (orientation: landscape) {
            @include media_query(L) {
              padding: 0px;
              padding-bottom: 10px;
              height: auto;
            }
          }
          // For tablet Portrait
          @media only screen and (min-width: 768px) and (orientation: portrait) {
            height: auto;
            padding: 0;
            padding-bottom: 10px;
          }
          ul {
            .terms {
              // font-family: "Montserrat";
              font-style: normal;
              font-size: 16px;
              line-height: 24px;
              // color: $darkGray;
              margin-bottom: 20px;
              &:last-child {
                margin-bottom: 0px;
              }
              @include media_query(M) {
                font-size: 12px;
                line-height: 18px;
              }
              // For tablet Portrait
              @media only screen and (min-width: 768px) and (orientation: portrait) {
                font-size: 20px;
                line-height: unset;
              }
            }
          }
        }
      }
    }
  }
  .bottom-section {
    position: absolute;
    box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.08);
    padding-bottom: 0;
    .inner-wrapper {
      height: 100%;
      .btn-wrapper {
        &.email-text {
          width: 100%;
        }
        @include media_query(M) {
          .btn-area {
            width: 100%;
            .app-btn {
              text-align: center;
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  &.close-terms {
    margin-left: -595px;
    @include media_query(M) {
      margin-left: -110%;
    }
  }
}
