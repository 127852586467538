.toggle-container {
  border: 1px solid $darkGreen;
  box-sizing: border-box;
  border-radius: 16px;
  width: 142px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 6px;
}
.right-box-container {
  height: 24px;
  cursor: pointer;
  width: 50%;
  font-weight: 700;
  font-size: 12px;
  padding: 4px;
}
.left-box-container {
  height: 24px;
  width: 50%;
  justify-content: center;
  text-align: center;
  // font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  padding: 4px;
}
.switch {
  display: flex;
  width: 100%;
  height: 34px;
  .slider {
    position: relative;
    background-color: $black !important;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid $black !important;
  }
  input:checked + .slider:before {
    transform: translateX(100%);
  }

  input:checked + .slider {
    // background-color: #006f44 !important;
    // border: none !important;
    &::before {
      border: 1px solid #006f44;
    }
  }
  //slider styling
  .slider:before {
    // position: absolute;
    height: 24px;
    width: 66px;
    left: 4px;
    right: 72px;
    top: calc(50% - 24px / 2);
    background: $darkGreen;
    // border: 1px solid $darkGreen;
    box-sizing: border-box;
    border-radius: 16px;
  }
}

input:checked .slider::before .right-box-container {
  color: $darkGreen;
}

input:checked + .slider {
  .right-box-container {
    color: $white;
  }
  .left-box-container {
    color: $darkGreen;
  }
}
input:checked .right-box-container {
  color: $darkGreen;
}

.right-box-container {
  color: $darkGreen;
  z-index: 10;
}
.left-box-container {
  color: $white;
  z-index: 10;
}
